@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.my-money {
  &__static {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 2.5rem;
    margin-bottom: 3rem;
    padding-inline: 0.25rem;
    @include m.media("last") {
      grid-template-columns: 1fr;
      grid-gap: 2.5rem;
    }

    &-header {
      padding-right: 0.5rem;
      @include m.media("second") {
        font-size: calc(0.75rem + 1px);
      }
      @include m.media("last") {
        padding-right: 0;
      }

      &-container {
        display: flex;
        flex-direction: row;
        z-index: 1;
        @include m.media("last") {
          justify-content: space-between;
          width: 60%;
        }
      }
    }

    &-block {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      position: relative;
      @include m.media("last", "min") {
        flex-direction: column;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          width: 0.25rem;
          height: 115%;
          background-color: var(--gray-light-color);
          top: 55%;
          transform: translateY(-50%);
          right: -0.5rem;
        }

        @include m.media("last") {
          &::after {
            width: 100%;
            height: 0.125rem;
            top: calc(100% + 1.25rem);
            left: 0;
            transform: translateY(0);
            right: initial;
          }
        }
      }
    }

    &-amount {
      font-weight: 500;
      @include m.media('last') {
        font-size: 0.813em;
      }
    }
  }
}

.portfolio-stats-base {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem 0.25rem;
  background-color: var(--gray-light-color);
  padding: 1rem;
  border-radius: 0.625rem;
  position: relative;
  min-height: 11rem;
  @include m.media("second") {
    min-height: 8rem;
    padding: 0.5rem;
  }
  @include m.media("last") {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, minmax(min-content, max-content));
    grid-gap: 0.5rem 0;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: var(--white-color);
    width: 0.25rem;
    height: 75%;
    @include m.centerWithPositioning;
    @include m.media("last") {
      display: none;
    }
  }
}

.portfolio-stats__block {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding-inline: 1rem;
  font-size: 0.875rem;
  @include m.media("second") {
    font-size: 0.75rem;
  }
  @include m.media("last") {
    font-size: 0.813rem;
    padding-right: 1rem;
    padding-block: 1rem;
    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        top: calc(100% + 0.25rem);
        left: 0;
        width: 100%;
        height: 0.125rem;
        background-color: var(--white-color);
      }
    }
  }
  @include m.media("last", "min") {
    &::after {
      content: "";
      position: absolute;
      width: 95%;
      height: 0.25rem;
      background-color: var(--white-color);
      @include m.centerLineWithPositioning;
      top: calc(100% + 1rem);
    }
  }
}

.portfolio-stats-closed {
  @extend .portfolio-stats-base;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  @include m.media("last") {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, minmax(min-content, max-content));
    grid-gap: 0.5rem 0;
  }
}

.portfolio-stats {
  @extend .portfolio-stats-base;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  @include m.media("last") {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, minmax(min-content, max-content));
    grid-gap: 0.5rem 0;
  }
}