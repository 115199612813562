@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.agent-report-filter {
  background-color: var(--gray-color-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 1.25rem;
  @include m.media("last") {
    flex-direction: column;
    gap: 1rem;
  }
  &__select {
    display: flex;
    align-items: center;
    gap: 1rem;
    @include m.media("last") {
      width: 100%;
    }
  }
  &__dates {
    display: flex;
    gap: 2rem;
    @include m.media("last") {
      flex-direction: column;
      gap: 1rem;
      width: 100%;
    }
  }
  &__icon {
    @include m.box(2rem);
    @include m.centerWithFlex;
    @include m.hideOnBreakpoint("last");
    background-color: var(--primary-green-color);
    border-radius: 0.375rem;
  }
  &__submit {
    & > button {
      padding-block: 0.2em;
      min-width: 8rem;
      color: var(--primary-green-color);
    }
    @include m.media("last") {
      &, & > button {
        width: 100%;
      }
    }
  }
}