@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.my-modal-close {
  position: absolute;
  top: 0.125rem;
  right: 0.125rem;
  @include m.box(2.5rem);
  border-radius: 50%;
  background-color: var(--primary-green-color-lighter);
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.5rem;
    height: 2px;
    background-color: var(--black-color);
    transition: all 0.3s;
  }
  &::before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  &::after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
  &:hover {
    &::before {
      transform: translateX(-50%) translateY(-50%) rotate(135deg);
    }
    &::after {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}