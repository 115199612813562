@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.profile-navigation {
  display: flex;
  gap: 2rem;
  @include m.hideOnBreakpoint("last");
  &__link {
    display: block;
    padding: 1.5rem 1rem;
    font-size: 0.875rem;
    color: var(--gray-color);
    position: relative;
    &:hover {
      color: inherit;
    }
    &.active {
      font-weight: 600;
      color: var(--black-color);
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0.25rem;
        background-color: var(--primary-green-color);
      }
    }
  }
}