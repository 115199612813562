@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;
@use "src/components/pages/projects/primary-market/grid" as pmg;

.primary-market {
  &-block-body {
    padding-block: 0 0;
  }
  &-block-header {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  &__download {
    position: absolute;
    top: 1.25rem;
    left: 1rem;
    width: 3rem;
    @include m.media("mobile") {
      top: 6.3rem;
      left: 80%;
    }
    @include m.media("last") {
      top: 7.5rem;
      left: 90%;
    }
    @include m.media(32em) {
      left: 85%;
    }
  }
}