@use "src/assets/scss/abstracts/mixins" as m;

.banner {
  width: 100%;
  border-radius: 1.25rem;
  background-color: var(--gray-light-color);
  position: relative;
  padding: 1.875rem 1.5rem 1.875rem 8rem;
  overflow: hidden;
  @include m.media("last") {
    padding: 1rem 2rem 1rem 2rem;
  }
  & > * {
    position: relative;
    z-index: 2;
  }
  &::before {
    content: "";
    position: absolute;
    background-color: var(--white-color);
    background-repeat: no-repeat;
    background-position: center 45%;
    left: -4rem;
    z-index: 1;
    @include m.centerColumnWithPositioning;
    @include m.box(14rem);
    border-radius: 50%;
  }
  &--alert {
    background-color: var(--pink-color);
    &::before {
      background-image: url("../../../assets/images/profile-banner-warning.png");
    }
  }
  &--info {
    background-color: var(--primary-green-color-lighter);
    &::before {
      background-image: url("../../../assets/images/profile-banner-money.png");
      background-position: center 50%;
      @include m.box(16rem);
    }
  }
  &--question {
    background-color: var(--primary-green-color-lighter);
    &::before {
      background-image: url("../../../assets/images/light_green_question_mark.svg");
      background-size: 5rem 5rem;
      background-position: center 50%;
      @include m.box(10rem);
      left: -1rem;
      @include m.media("last") {
        background-color: var(--primary-green-color-lighter);
        background-image: none;
      }
    }
  }
  &--warning {
    background-color: var(--primary-orange-color);
    &::before {
      background-image: url("../../../assets/images/profile-banner-warning.png");
    }
  }
}