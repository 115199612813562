@use "../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../assets/scss/abstracts/variables" as v;

.myModal {
	border: solid #22a873 2px;
	box-shadow: 0 0 10px rgba(34, 168, 115, 0.5);
	width: auto;
	padding: 0;
	min-width: 70%;
	min-height: 50%;
	max-width: calc(100% - 2rem);
	max-height: calc(100% - 2rem);
	overflow: auto;
	&__content {
		flex: 1;
		width: 100%;
		display: flex;
		flex-direction: column;
		> * {
			width: 100%;
			flex-grow: 1;
		}
	}
}