@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

$grid-columns: minmax(17rem, 1fr) 7rem 8rem repeat(3, 1fr) minmax(15rem, 1fr);
$grid-columns-first: minmax(12rem, 1fr) 6rem 7rem repeat(3, 1fr) minmax(10rem, 1fr);
$grid-columns-second: minmax(10rem, 1fr) 5rem 6rem repeat(3, 1fr) minmax(8rem, 1fr);
$grid-columns-mobile: minmax(15.5rem, 1fr) 7rem 5rem max-content;

$last-breakpoint: 41.25em;

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: "debt investment date type hold money description";
  grid-column-gap: 1.5rem;
  @include m.media("first") {
    grid-template-columns: $grid-columns-first;
  }
  @include m.media("second") {
    grid-template-columns: $grid-columns-second;
    grid-column-gap: 1rem;
  }
  @include m.media("mobile") {
    grid-template-columns: $grid-columns-mobile;
    grid-template-rows: minmax(2rem, max-content) max-content;
    grid-template-areas:
            "debt investment date hold"
            "type money money description ";
    grid-gap: 0.5rem 0;
  }
  @include m.media($last-breakpoint) {
    grid-template-columns: repeat(3, 30%);
    grid-column-gap: 0.5rem;
    align-items: center;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
            "debt type money"
            "debt type date"
  }
}