@use "assets/scss/abstracts/mixins" as m;


.filter {
  @include m.centerColumnWithFlex;
  border-bottom: 0.3rem solid var(--gray-light-color);
  &__container {
    @include m.centerLineWithFlex;
    justify-content: space-between;
    align-items: normal;
    width: 80%;
    margin-bottom: 1.5rem;
    @include m.media("last") {
      @include m.centerColumnWithFlex;
    }
  }
  &__containerOnlyForDate {
    @include m.centerLineWithFlex;
    justify-content: center;
    align-items: normal;
    width: 80%;
    margin-bottom: 1.5rem;
    @include m.media("last") {
      @include m.centerColumnWithFlex;
    }
  }
  &__type {
    @include m.centerColumnWithFlex;
    width: 45%;
    @include m.media("first") {
      width: 49%;
    }
    @include m.media("last") {
      width: 100%;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }

    }
  }
  &__typeOnlyForDate {
    @include m.centerColumnWithFlex;
    width: 80%;
    @include m.media("first") {
      width: 80%;
    }
    @include m.media("last") {
      width: 100%;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }

    }
  }
  &__title {
    margin-bottom: 1.5rem;
    @include m.media("last") {
      font-size: var(--font-size-xs);
      margin-bottom: 1rem;
    }
  }
  &__buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }
  &__button, &__button__active {
    height: 2rem;
    min-width: 2rem;
    padding-inline: 1rem;
    color: var(--gray-light-color-2);
    background-color: var(--gray-light-color);
    @include m.centerWithFlex;
    font-weight: 600;
    font-size: var(--font-size-m);
    cursor: pointer;
    border-radius: 0.25rem;
    border: none;
    @include m.media("last") {
      font-size: var(--font-size-xs);
    }
    &__active {
      color: var(--white-color);
      background-color: var(--primary-green-color);
    }
  }

  &__dateContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include m.media("second") {
      width: 90%;
    }
  }
  &__buttonAccept {
    width: 80%;
    margin-bottom: 2rem;
  }
}