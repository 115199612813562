@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.detailed-project-mobile-navigation {
  @include m.hideOnBreakpoint("last", "min");
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem 0.625rem;
  margin-bottom: 2rem;
  &__link {
    display: block;
    width: 100%;
    min-height: 3rem;
    border: 2px solid var(--white-color);
    font-size: 0.75rem;
    color: var(--gray-color-3);
    border-radius: 1.25rem;
    @include m.centerWithFlex;
    &:last-child {
      grid-column: 1 / -1;
    }
    &:hover {
      color: inherit;
    }
    &.active {
      color: var(--black-color);
      border-color: var(--primary-green-color);
    }
  }
}