@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/components/header" as hv;
@use "src/assets/scss/abstracts/variables" as v;

.mobile-navigation {
  @include m.hideOnBreakpoint(map-get(v.$container-breakpoints, "mobile"), 'min');
  overflow: hidden;
  max-height: 0;
  padding-block: 0;
  transition: v.$default-transition;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  &.active {
    max-height: 60vh;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-block: 0 1rem;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__list {
    padding-left: 1rem;
  }
  &__login {
    @include m.centerLineWithFlex;
    gap: 1rem;
  }
  &__link {
    display: block;
    width: 100%;
    padding: 0.65rem;
    color: var(--black-color);
    transition: all 0.3s;
    &:hover {
      color: var(--primary-green-color);
    }
  }
  &__money {
    display: flex;
    align-items: center;
    gap: 1rem;
    & > * {
      flex: 1;
      padding-block: 0.25rem;
    }
  }
}