
@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;
@use "../grid" as gr;

$grid-areas: primary category sum actual-payment forecast term simple yearly transactions stages actions;

@mixin columnUnderscore() {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 100%;
    background-color: var(--gray-light-color);
  }
}

.active-project {
  padding: 1.75rem 1.5rem 1.5rem;
  transition: v.$default-transition;
  background-color: var(--white-color);
  @include gr.gridValues;
  @include m.media("second") {
    padding-block: 1.5rem 1.25rem;
  }
  &:not(:last-child) {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0.25rem;
      background-color: var(--gray-light-color);
    }
  }
  &__stages--link {
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 0.25rem;
    background: var(--primary-green-color-lighter);
  }
  @include m.media("mobile", "min") {
    &:hover {
      background-color: var(--gray-light-color);
    }
  }
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }
  &__link-to-project {
    color:inherit;
    &:hover {
      color:inherit;
    }
  }
  &__column {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    @include m.media("second") {
      font-size: 0.75rem;
    }
    @include m.media("mobile") {
      flex-direction: column;
      font-size: 0.875rem;
      padding-block: 0.375rem;
      justify-content: flex-start;
    }
    @include m.media("last") {
      justify-content: space-between;
    }
  }
  &__primary {
    @include m.media("mobile") {
      justify-content: flex-end;
      align-items: stretch;
    }
  }
  &__primary, &__category {
    @include m.media("mobile") {
      @include columnUnderscore;
    }
  }
  &__title {
    position: relative;
    @include m.media("second", "min") {
      padding-right: 1.25rem;
    }
  }
  &__category {
    justify-content: center;
    @include m.media("mobile") {
      align-items: flex-end;
    }
  }
  &__name {
    @include m.media("first") {
      @include m.lineClamp(3);
    }
    @include m.media("mobile") {
      @include m.lineClamp(2);
    }
    @include m.media("last") {
      @include m.lineClamp(1);
    }
  }
  &__sum, &__forecast, &__actual-payment, &__simple, &__yearly {
    @include m.media("second") {
      justify-content: center;
    }
    @include m.media("mobile") {
      justify-content: flex-start;
    }
    @include m.media("last") {
      justify-content: space-between;
    }
  }
  &__simple, &__forecast, &__yearly {
    @include m.media("last") {
      align-items: flex-start;
    }
  }
  &__term, &__transactions {
    @include m.media("last") {
      align-items: flex-end;
    }
  }
  &__text {
    @include m.media("last") {
      font-size: 0.75rem;
    }
  }
  &__sum {
    @include m.media("mobile") {
      align-items: flex-start;
    }
  }
  &__transactions {
    @include m.media("second") {
      padding-left: 0.25rem;
    }
    @include m.media("mobile") {
      padding-left: 0;
    }
  }
  &__link {
    font-size: 0.8rem;
    padding: 0.7em 1.25em;
    @include m.media("second") {
      padding: 0.9em;
      font-size: 0.65rem;
    }
    @include m.media("mobile") {
      font-size: 0.9rem;
    }
  }
  &__progress {
    @include m.media("last") {
      flex: 3;
      width: 75%;
    }
  }
  @include m.media("last") {
    &__sum, &__forecast, &__term, &__simple, &__yearly, &__transactions {
      @include columnUnderscore;
    }
  }
}