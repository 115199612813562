@use "../../../../assets/scss/abstracts/variables" as v;
@use "../../../../assets/scss/abstracts/mixins" as m;

$referral-grid-columns: 1fr 1.5fr minmax(2rem, 1fr);
$referral-last-breakpoint: 41.25em;

.referral-users-section {
  max-width: 87em;
}

.referral-users-header {
  display: grid;
  grid-template-columns: $referral-grid-columns;
  gap: 1.5rem;
  align-items: start;
  justify-items: start;
  padding: 1rem;
  background-color: #fbfbfb;
  color: var(--gray-color-3);
  font-size: 0.75rem;
  border-radius: 0.75rem;
  box-sizing: border-box;
  
  > * {
    justify-self: start;
    align-self: start;
    text-align: left;
  }
  
  @include m.media($referral-last-breakpoint) {
    gap: 1rem;
  }
}

.referral-users {
  &-body {
    border-radius: 0.75rem;
    padding-block: 0 4rem;
    
    @include m.media("mobile") {
      padding-bottom: 2rem;
    }
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 1.5rem 2.25rem;
    font-size: 0.875rem;
    color: #000;
  }
  
  &__list__item {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #F6F7F8;
    width: 100%;
    
    > * {
      flex: 1;
      text-align: left;
    }
    
    &:last-child {
      border-bottom: none;
    }
    
    span {
      text-align: left;
    }
    
    @include m.media("mobile") {
      padding-inline: 1rem;
    }
  }
}