@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/components/header" as hv;
@use "src/assets/scss/abstracts/mixins" as m;

$navigation-padding: 1rem;

.header {
  background-color: var(--white-color);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  border-radius: 0 0 hv.$header-border-radius hv.$header-border-radius;
  box-shadow: 0 3px 3.125rem rgba(v.$black-color, 0.1);
  &__wrapper {
    position: relative;
  }
  &__wrapper__active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    z-index: 10;
  }
  &__logo {
    max-width: 11rem;
    aspect-ratio: 5 / 3;
    @include m.media("last") {
      max-width: 6.25rem;
    }
  }
  &__navigation {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 6rem;
    padding-block: $navigation-padding;
    position: relative;
    @include m.media("last") {
      gap: 0;
    }
    &__left, &__right {
      display: flex;
      gap: 2.5rem;
      align-items: stretch;
    }
    &__left {
      flex: 1;
    }
  }
  &__logo-container {
    @include m.centerLineWithFlex;
  }
  &__projects {
    @include m.centerLineWithFlex;
    &-link {
      @include m.centerLineWithFlex;
      gap: 0.75rem;
      color: var(--black-color);
    }
  }
  &__public-menu {
    display: flex;
    align-items: stretch;
    gap: 1.5rem;
  }
  &__logged-menu {
    flex: 1;
    justify-content: flex-end;
    & .header__link {
      color: var(--gray-dark-color);
      font-size: 80%;
    }
  }
  &__public-element {
    padding: 0.15rem;
    @include m.centerLineWithFlex;
  }
  &__link {
    @include m.centerWithFlex;
    height: 100%;
    padding: 0.35rem;
    @include m.underscore(100%, calc(100% + $navigation-padding));
    transition: color 0.3s;
    &--public {
      color: var(--black-color);
    }
    &--profile {
      gap: 1.5rem;
    }
    &:hover {
      color: var(--primary-green-color);
    }
  }
  &__mobile-open {
    @include m.centerLineWithFlex;
    @include m.hideOnBreakpoint("mobile", 'min');
    &__cross {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.625rem;
    }
  }
  &__auth-menu, &__user-menu, &__public-menu, &__projects {
    @include m.hideOnBreakpoint("mobile", 'max');
  }
  &__auth-menu {
    @include m.centerLineWithFlex;
    gap: 1rem;
  }
  &__notifications {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &__notification-count {
      position: absolute;
      top: -0.2rem;
      right: -0.8rem;
      background-color: var(--primary-green-color-darker);
      color: var(--white-color);
      border-radius: 50%;
      padding: 0.1rem 0.5rem;
      font-size: 0.8rem;
      display: inline-block;
      min-width: 1.25rem;
      text-align: center;
    }
  }
  &__mobile__notifications {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &__notification-count {
      position: absolute;
      top: -0.2rem;
      right: -0.8rem;
      font-size: 0.8rem;
      background-color: var(--primary-green-color-darker);
      color: var(--white-color);
      border-radius: 50%;
      padding: 0.1rem 0.5rem;
      display: inline-block;
      min-width: 1rem;
      text-align: center;
    }
  }
  &__user-menu {
    display: flex;
    align-items: stretch;
    gap: 1rem;
    padding: 0.15rem;
  }
  &__profile {
    display: flex;
    align-items: center;
  }
  &__mobile-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 0.25rem;
    position: relative;
    @include m.hideOnBreakpoint(map-get(v.$container-breakpoints, "mobile"), 'min');
  }
  &__mobile-button {
    @include m.hideOnBreakpoint(map-get(v.$container-breakpoints, "mobile"), 'min');
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translateX(-50%) rotate(180deg);
    @include m.box(2rem);
    border-radius: 50%;
    background: url("../../../../src/assets/images/svg/mobile-arrow.svg") no-repeat center var(--white-color);
    background-size: 0.625rem;
    transition: v.$default-transition;
    cursor: pointer;
    &.active {
      transform: translateX(-50%) rotate(0);
    }
  }
}
.mobile-navigation__money {
  padding-block: 0.5rem;
  @include m.hideOnBreakpoint(map-get(v.$container-breakpoints, "mobile"), 'min');
}