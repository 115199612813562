@use "src/assets/scss/abstracts/mixins" as m;

.my-projects__block-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  @include m.media("mobile") {
    padding: 0.25rem;
  }
  @include m.media("last") {
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
}

.my-projects__collected {
  @include m.media("last") {
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
}

.my-projects-header-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &__status-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 5rem;
    width: 50%;
    z-index: 2;
    @include m.media("second") {
      width: 50%;
      flex-flow: wrap;
      justify-content: flex-start;
    }
    @include m.media("mobile") {
      flex-direction: column;
      width: 40%;
      margin-left: 1rem;
    }
    @include m.media("last") {
      width: 70%;
      gap: 0.5rem;
    }
  }
  &__status {
    height: 2rem;
    min-width: 2rem;
    padding-inline: 1rem;
    color: var(--gray-light-color-2);
    background-color: var(--white-color);
    @include m.centerWithFlex;
    font-size: 0.875rem;
    cursor: pointer;
    border-radius: 0.25rem;
    &.active {
      color: var(--white-color);
      background-color: var(--primary-green-color);
    }
    @include m.media("second") {
      margin: 0.5rem;
    }
    @include m.media("last") {
      font-size: 0.8rem;
      margin: 0;
    }
  }
}