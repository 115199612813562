@use "src/assets/scss/abstracts/variables" as v;
@use "src/components/pages/detailed-project/styles/mixins" as dm;

.project-economic {
  @include dm.mountAnimation;
  padding: 1rem;
  &-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &-body {
    padding-inline: 2rem;
  }
  &__header {
    font-size: var(--font-size-sm);
    margin-bottom: 1.25rem;
  }
  &__blocks {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &__block {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
  }
  &__heading {
    font-weight: 700;
    font-size: 0.875rem;
  }
  &__text {
    font-size: var(--font-size-s);
  }
  &__owners {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.business-block {
  &__text {
    margin-bottom: 1.25rem;
    font-size: var(--font-size-s);
  }
  &__tables {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__table {
    & > thead {
      background-color: #fbfbfb;
      text-transform: uppercase;
      color: var(--gray-color-3);
    }
    & td {
      padding: 1rem 1rem 1rem 1.5rem;
      width: 19.75rem;
    }
    & td+td {
      width: auto;
    }
  }
}