@use "src/assets/scss/abstracts/variables" as v;

.project-filter-clear {
  padding: 0.5rem;
  border-radius: 0.375rem;
  background-color: var(--gray-light-color);
  font-size: 0.75rem;
  display: flex;
  align-items: stretch;
  gap: 0.5rem;
  &__button {
    cursor: pointer;
    & > img {
      transition: v.$default-transition;
      transform: rotate(0);
    }
    &:hover {
      & > img {
        transform: rotate(90deg);
      }
    }
  }
}