@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.my-money {
  &__short-stats {
    grid-area: short;
    display: grid;
    grid-template-columns: minmax(17rem, 1fr) repeat(5, 1fr);
    grid-gap: 1rem;
    @include m.media("first") {
      grid-template-columns: repeat(6, 1fr);
    }
    @include m.media("second") {
      grid-template-columns: 1fr 1.5fr 1.5fr;
      grid-gap: 0.625rem;
    }
    @include m.media("last") {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.short-stat {
  background-color: var(--gray-light-color);
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: v.$default-box-shadow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.375rem;
  @include m.media("last") {
    &:first-child {
      display: none;
    }
    &:last-child {
      grid-column: 1 / -1;
    }
  }
  &__heading {
    font-size: 0.875rem;
    color: var(--gray-color-3);
  }
  &__value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--projects {
      justify-content: space-around;
      align-items: flex-end;
      @include m.media("first") {
        gap: 0.5rem;
      }
    }
  }
  &__text {
    font-size: 1.5rem;
    @include m.media("first") {
      font-size: 1.25rem;
    }
  }
  &__projects {
    justify-content: space-around;
    gap: 1rem;
    background-color: var(--white-color);
    border-radius: 0.5rem;
    padding: 0.125rem 0.375rem;
    @include m.centerLineWithFlex;
    min-width: 11rem;
    position: relative;
    & > * {
      flex: 1;
    }
  }
  &__project {
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      width: 0.25rem;
      height: 85%;
      background-color: var(--gray-light-color);
      top: 100%;
      left: 115%;
      transform: translateX(-115%) translateY(-115%);
    }
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &-type {
      color: var(--primary-green-color);
      font-size: 0.75rem;
    }
  }
}
