.section {
  padding-block: 1.5rem;
}
.main-section {
  background: url("../../../../src/assets/images/main-bg.png") no-repeat;;
  background-size: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}