@use "src/assets/scss/abstracts/mixins" as m;

.alert-list {
  position: fixed;
  z-index: 10;
  top: 10rem;
  @include m.centerLineWithPositioning;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__element {
    &--enter, &--appear {
      opacity: 0;
      transform: translateX(-50%);
    }
    &--enter-active, &--appear-active {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.5s;
    }
    &--exit {
      opacity: 1;
      transform: translateX(0);
    }
    &--exit-active {
      opacity: 0;
      transform: translateX(50%);
      transition: all 0.5s;
    }
  }
  //TODO: ADD MEDIA QUERY FOR SMALLER SCREENS
}