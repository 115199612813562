@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.order-confirmation {
  padding: 1rem;

  &__logo {
    margin: 1rem;
  }

  &__success {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  &__description {
    margin-bottom: 2rem;
  }

}