@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.block-header {
  padding: 0.25rem 0.25rem 0;
  &__inner {
    border-radius: v.$block-border-radius v.$block-border-radius 0 0;
    background-color: v.$lighter-gray-color;
    display: flex;
    align-items: center;
    & > * {
      width: 100%;
    }
  }
  &--padding {
    padding: 1.25rem 0.75rem;
  }
  &--single {
    padding: 0.25rem;
    border-radius: v.$block-border-radius;
  }
}