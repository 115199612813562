@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.gear-button {
  background-color: var(--white-color);
  border-radius: 50%;
  @include m.box(2.125rem);
  @include m.centerWithFlex;
  cursor: pointer;
  transition: v.$default-transition;
  border: none;
  & > img {
    transition: v.$default-transition;
    transform: rotate(0);
  }
  &:hover {
    background-color: #f1f1f1;
    & > img {
      rotate: 180deg;
    }
  }
}