@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.date-select {
  & .react-date-picker {
    &__wrapper {
      border: none;
      cursor: pointer;
    }
    &__calendar {
      inset: 150% -75% auto auto !important;
      width: 250px;
      @include m.media("last") {
        inset: 150% -60% auto auto !important;
      }
    }
  }
  & .react-calendar {
    border: none;
    border-radius: 0.625rem;
    box-shadow: v.$default-box-shadow;
    &__viewContainer {
      padding: 0 0.75rem 0.75rem;
    }
    &__navigation {
      margin-bottom: 0;
      &__arrow {
        color: var(--primary-green-color);
        font-size: 1.5em;
      }
      &__label {
        text-transform: capitalize;
        font-size: 0.75rem;
      }
    }
    &__month-view {
      gap: 0.1rem;
      &__weekdays {
        &__weekday {
          color: var(--gray-light-color-2);
          & > abbr {
            text-decoration: none;
            font-weight: 400;
          }
        }
      }
      &__days {
        &__day {
          font-size: 0.75rem;
          &--neighboringMonth {
            color: rgb(153, 153, 153) !important;
          }
        }
      }
    }
    &__tile {
      color: var(--black-color);
      border-radius: 0.25rem;
      &--active {
        &, &:enabled:hover, &:enabled:focus {
          background-color: var(--primary-green-color-lighter);
        }
      }
      &--now {
        background-color: inherit;
        &:enabled:hover {
          background-color: #e6e6e6;
        }
      }
    }
  }
}