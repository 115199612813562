@use "src/assets/scss/abstracts/mixins" as m;

$grid-areas: series number issued-by issued-date code;

.identification__passport-block {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.25rem;
  grid-template-areas:
        "series number issued-date code code"
        "issued-by issued-by issued-by issued-by issued-by";
  @include m.media("second") {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "series number"
        "issued-date code"
        "issued-by issued-by";
  }
  @include m.media("last") {
    grid-template-columns: 1fr;
    grid-template-areas:
        "series"
        "number"
        "issued-date"
        "code"
        "issued-by";
  }
}

@each $area in $grid-areas {
  .identification__passport-#{$area} {
    grid-area: #{$area};
  }
}

.identification__file-snippet {
  @include m.box(2rem);
  border-radius: 50%;
  @include m.centerWithFlex;
  background-color: var(--gray-color-2);
  &--green {
    background-color: var(--primary-green-color);
  }
}

.identification__file-name {
  margin-block: 1.875rem 2rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.identification__passport-files {
  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    &.verified {
      grid-template-columns: repeat(2, 1fr);
    }
    grid-gap: 0.875rem 1.5rem;
    @include m.media("mobile") {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, minmax(min-content, max-content));
      grid-row-gap: 1.25rem;
    }
  }
}

.identification-files__warning {
  margin-top: 1.125rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-color-3);
  &-size {
    white-space: nowrap;
  }
}