@use "../../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../../assets/scss/abstracts/variables" as v;

.close-button {
  display: block;
  background-color: var(--gray-color-2);
  border: none;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s;
  &:hover {
    background-color: var(--gray-color);
  }
  &--small {
    @include m.box(2.5rem);
  }
  &--medium {
    @include m.box(3.5rem);
    @include m.media("last") {
      @include m.box(3rem);
    }
  }
  &--large {
    @include m.box(4.5rem);
  }
  &::after, &::before {
    content: '';
    background-color: v.$black-color;
    display: block;
    height: 2px;
    width: 45%;
    border-radius: 0.5rem;
    position: absolute;
    @include m.centerWithPositioning;
  }
  &::after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
  &::before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
}