.input-password {
  background-color: var(--gray-light-color);
  border: none !important;
  & input {
    padding-inline: 0.75rem;
  }
  ::-ms-reveal {
    display: none;
  }
  & label {
    left: 0.8rem;
    color: var(--gray-color-3);
    &.focused {
      font-size: 0.75rem;
    }
  }
  &__toggle {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.5rem;
    max-height: 0.875rem;
    cursor: pointer;
  }
}