.money {
  &--write-off {
    & > span {
      color: var(--primary-green-color);
    }
  }
  &--penalty {
    & > span {
      color: var(--black-color);
    }
  }
  &--additional {
    & > span {
      color: var(--gray-light-color-2);
    }
  }
  &__rubles {
    color: var(--black-color);
  }
  &__pennies {
    color: var(--gray-color-3);
  }
}