@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.alert-notification {
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: calc(min(90vw, 35rem));
  &__close {
    width: 2rem !important;
    height: 2rem !important;
  }
  &--error {
    background-color: #ff7f7f;
  }
  &--warning {
    background-color: var(--primary-orange-color);
  }
  &--info {
    background-color: v.$primary-green-color;
  }
}