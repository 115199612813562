@use "../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../assets/scss/abstracts/variables" as v;

.back-button {
  border-radius: 50%;
  @include m.box(2.5rem);
  background-color: v.$primary-green-color-lighter;
  @include m.centerWithFlex;
  font-size: 1.5rem;
  color: v.$primary-green-color;
  font-weight: 700;
  border: none;
}