@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.balance-stats {
  &__block-header {
    min-height: 5.625rem;
    @include m.media("second") {
      min-height: 5rem;
    }
  }
  &__icon {
    @include m.box(2.5rem);
    background-color: var(--primary-green-color-lighter);
    border-radius: 50%;
    @include m.centerWithFlex;
  }
  &__header {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  &__heading {
    font-size: 1.5rem;
  }
  &__content {
    padding-block: 0.625rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__row {
    display: flex;
    padding-block: 0.625rem;
    font-size: calc(0.875rem + 1px);
    & > * {
      flex: 1;
    }
    &:first-child {
      padding-top: 0;
      @include m.media("last") {
        margin-top: 1.5rem;
      }
    }
  }
  &__row-container {
    display: flex;
    flex-direction: row;
    z-index: 1;
    @include m.media("last") {
      justify-content: space-between;
      width: 60%;
    }
  }
  &__row-title {
    padding-right: 0.5rem;
    @include m.media("last") {
      padding-right: 0;
    }
  }
  &__block {
    background-color: var(--gray-light-color);
    border-radius: 0.625rem;

    &-line {
      padding-block: 0.625rem;
      position: relative;
      display: flex;
      align-items: center;
      font-size: calc(0.875rem + 1px);
      & > * {
        flex: 1;
      }
      &:not(:last-child) {
        border-bottom: 2px solid var(--gray-light-color);
        border-top: 2px solid var(--gray-light-color);
      }
    }
    &-body {
      padding-block: 0;
    }
  }
  &__row, &__block-line {
    @include m.media("second") {
      font-size: calc(0.75rem + 1px);
    }
  }
  &__right {
      text-align: right;
  }
}