@use "src/assets/scss/abstracts/mixins" as m;

.change-avatar {
  position: relative;
  width: fit-content;
  &__photo {
    @include m.box(7rem);
    border-radius: 50%;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  &__edit {
    position: absolute;
    inset: auto 0 0 auto;
    border-radius: 50%;
    background-color: var(--primary-green-color);
    @include m.box(2rem);
    @include m.centerWithFlex;
    color: var(--white-color);
    cursor: pointer;
  }
}