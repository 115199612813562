@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.notifications-list {
  &__block-header {
    padding: 1.125rem 1.25rem;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__select {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}