@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.onboardingModal {
	border-color: #22a873 !important;
	border-radius: 10px !important;
	
	&__content {
		width: auto;
		min-width: 80%;
		@include m.media("mobile") {
			display: flex;
			flex-direction: column;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: white;
			z-index: 9999;
		}
		height: 100%;
		position: relative;
		border-color: #22a873;
	}
	
	&__footer {
		display: flex;
		align-self: flex-end;
		justify-content: space-between;
		align-items: center;
	}
	
	&__stepsCount {
		position: absolute;
		top: 2.5rem;
		right: 2rem;
		font-size: 16px;
		color: #333;
	}
}

.onboardingMobile__footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.paginator {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	margin-top: 2rem;
}

.paginatorDot {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #d9d9d9;
}

.paginatorDot--active {
	background-color: #22a873;
}

.previousButton,
.nextButton {
	border-color: #e6e6e6;
	cursor: pointer;
	padding: 1rem 2rem;
	margin: 2rem;
	
	&.nextButton {
		@include m.media("mobile") {
			margin:  2rem 1rem;
			width: 85%;
			padding: 1rem 2rem;
		}
		margin-left: auto;
		margin-right: 44%;
	}
}

.logo__green {
	@include m.media("mobile") {
		display: none;
	}
	position: absolute;
	width: 175px;
	height: 43px;
	right: 2.5rem;
}
.backgroundActive {
	@include m.media("mobile") {
		background: none;
	}
	background: url('../../../assets/images/svg/background-logo.svg') center bottom no-repeat;
	background-size: 45%;
	width: 100%;
	height: 100%;
}