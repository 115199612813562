.survey-documents {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  &__container {
    display: flex;
    flex-direction: row;
  }
  &__name {
    margin-top: 0.5rem;
  }
  &__button {
    text-decoration: none;
    color: var(--blue-color);
  }
}