@use "src/assets/scss/abstracts/variables" as v;

.option-box {
 &__content {
   position: absolute;
   top: 115%;
   left: 0;
   overflow: hidden;
   max-height: 0;
   z-index: 5;
   background-color: var(--white-color);
   border-radius: 0.625rem;
   transition: v.$default-transition;
   box-shadow: v.$default-box-shadow;
   &.active {
     overflow: scroll;
     scrollbar-width: none;
     max-height: 8rem;
   }
 }
  &__item {
    cursor: pointer;
    font-size: 0.875rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &.selected {
      background-color: var(--light-blue-color);
      color: var(--white-color);
    }
  }
}