@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.transaction-date-range-calendar-select {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @include m.media("mobile", "max") {
    position: relative;
    z-index: 2;
  }
  &__title {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
  }
  &__show {
    @include m.media(40em, "max") {
      display: none;
    }
  }
  &__image {
    transition: v.$default-transition;
    transform: rotate(180deg);
    &.active {
      transform: rotate(360deg);
    }
    @include m.media(26rem) {
      width: 0.75rem;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}