@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.project-dropdown {
  transition: v.$default-transition;
  max-height: 0;
  overflow: hidden;
  &.active {
    max-height: 100vh;
  }
  &-body {
    padding-block: 0;
    transition: v.$default-transition;
    &.active {
      padding-block: 1.5rem;
    }
  }
  &__header, &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__heading {
    @include m.media("mobile", "min") {
      font-size: 1.5rem;
    }
  }
  &__toggle {
    & > button {
      color: var(--primary-green-color);
    }
    @include m.media("mobile") {
      font-size: 0.875rem;
    }
  }
  &__footer-close {
    @include m.box(2rem);
    background-color: var(--white-color);
    @include m.centerWithFlex;
    border-radius: 50%;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
}