@use "../../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../../assets/scss/abstracts/variables" as v;
@use "../grid" as gr;

.order-projects {
  &-header {
    @include gr.gridValues;
    &__item {
      display: flex;
      align-items: flex-end;
    }
    &__collected, &__category {
      justify-content: center;
    }
    &__sum, &__forecast, &__simple, &__yearly {
      @include m.media("second") {
        text-align: center;
      }
    }
    &__date {
      @include m.media("second") {
        padding-left: 0.25rem;
      }
    }
  }
  &__paginator {
    @include m.centerWithFlex;
    padding-bottom: 1rem;
  }
}