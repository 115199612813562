@use "src/assets/scss/abstracts/variables" as v;

.rc-slider-mark-text {
  white-space: nowrap;
  color: v.$black-color;
  &:first-child {
    transform: translateX(-20%) !important;
  }
  &:last-child {
    transform: translateX(-95%) !important;
  }
}