@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;
.confirm-form {
  &__header {
    font-size: 0.875rem;
    line-height: 1.25rem;
    max-width: 15rem;
    margin-bottom: 1rem;
  }
  &__container {
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: var(--gray-dark-color);
  }
  &__input {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 2rem;
  }
  &__controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    font-size: 0.875rem;
  }
  &__countdown {
    text-align: center;
    max-width: 12rem;
    color: var(--gray-color-3);
  }
  &__action {
    color: var(--primary-green-color);
    background: none;
    border: none;
  }
}

.sms-help {
  position: relative;
  cursor: pointer;
  &__answer {
    cursor: initial;
    position: absolute;
    z-index: 1;
    border-radius: 1.25rem;
    background-color: v.$gray-color-2;
    padding: 1rem;
    min-width: 30rem;
    left: -10.5rem;
    bottom: 2rem;
    visibility: hidden;
    opacity: 0;
    @include m.media("last") {
      min-width: 19rem;
    }
    @include m.media("last") {
      left: -4.5rem;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -1rem;
      left: 13rem;
      background-image: url("../../../../assets/images/gray-rectangle.svg");
      height: 20px;
      width: 40px;
      @include m.media("last") {
        left: 8rem;
      }
    }
    &--enter {
      visibility: hidden;
      opacity: 0;
      &-active {
        opacity: 1;
        visibility: visible;
        transition: all 0.3s;
      }
      &-done {
        opacity: 1;
        visibility: visible;
      }
    }
    &--exit {
      opacity: 1;
      visibility: visible;
      &-active {
        opacity: 0;
        visibility: visible;
        transition: all 0.3s;
      }
      &-done {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &__min-sum {
    padding-top: 1.5rem;
  }
  &__text {
    color: v.$black-color;
    font-size: 0.9rem;
    line-height: 110%;
  }
}