@use "../../../../assets/scss/abstracts/mixins" as m;

.email-modal__add, .email {
  @include m.centerLineWithFlex;
  justify-content: space-between;
  gap: 1rem;
}

.email__actions {
  @include m.centerLineWithFlex;
  gap: 1rem;
}