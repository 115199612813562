@use "assets/scss/abstracts/variables" as v;
@use "assets/scss/abstracts/components/header" as hv;
@use "assets/scss/abstracts/mixins" as m;
@use "../DocumentsElements/grid" as gr;


.documents {
  @include m.centerColumnWithFlex;
  margin-bottom: 3rem;
  &__filtersContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-end;
    margin: 1rem 6rem 1rem 0;
    @include m.media("last") {
      margin: 0;
    }
  }
  &__title {
    @include m.centerColumnWithFlex;
    margin-top: 2rem;
    width: 80%;
  }
}

