@use "src/assets/scss/abstracts/mixins" as m;

.projects-filter-clear-all {
  display: flex;
  align-items: stretch;
  gap: 0.75rem;
  color: var(--gray-color-3);
  font-size: 0.75rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  & > div {
    @include m.media("second") {
      white-space: nowrap;
    }
  }
}