@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.transaction-direction-select {
  display: flex;
  align-items: center;
  gap: 1rem;
  @include m.media("mobile", "max") {
    position: relative;
    z-index: 2;
  }
  &__show {
    //TODO: MOVE 40EM TO SPECIFIC FILE
    @include m.media(40em, "max") {
      display: none;
    }
  }
  &__content {
    &.active {
      overflow: hidden;
      max-height: 15rem;
      min-height: 10.25rem;
    }
    & .option-box__list {
      min-width: 10rem;
      padding: 1rem;
    }
    & .option-box__item {
      &:first-child {
        margin-bottom: 1.875rem;
      }
      transition: v.$default-transition;
      &:hover, &.active {
        color: var(--primary-green-color);
      }
    }
  }
  &__heading {
    color: var(--primary-green-color);
    @include m.media(26rem) {
      font-size: 0.75rem;
    }
  }
  &__image {
    transition: v.$default-transition;
    transform: rotate(180deg);
    &.active {
      transform: rotate(360deg);
    }
    @include m.media(26rem) {
      width: 0.75rem;
    }
  }
}