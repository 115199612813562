@use "src/assets/scss/abstracts/mixins" as m;

.survey {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  &__container {
    display: grid;
    grid-template-columns: 1fr 28.5rem;
    grid-gap: 1.5rem;
    @include m.media("second") {
      grid-template-columns: 1fr 20rem;
    }
    @include m.media("mobile") {
      grid-template-columns: 1fr;
    }
  }

  &__header {
    @include m.media("last") {
      font-size: 1.2rem;
      margin: 0.8rem;
    }
  }
  &__project {
    font-size: 1.5rem;
    @include m.media("last") {
      font-size: 1rem;
      margin: 0.8rem;
    }
  }

  &__description {
    padding: 1rem;
    @include m.media("last") {
      font-size: 0.8rem;
    }
  }


}