.referral-user {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  padding-inline: 1.5rem;
  padding-block: 0.75rem 2.25rem;
}
.referral-users {
  &__heading {
    display: flex;
    justify-content: space-between;
    background-color: #FBFBFB;
    color: var(--gray-color-3);
    font-size: 0.75rem;
    text-align: left;
    box-sizing: border-box;
    padding: 1rem;
    border-bottom: 1px solid #F6F7F8;
  }

  &__column {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #F6F7F8;

    span {
      flex: 1;
      text-align: left;
      font-size: 0.875rem;
      color: #000;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
