@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/components/header" as hv;
@use "src/assets/scss/abstracts/mixins" as m;

.footer {
  z-index: 1;
  background-color: #09703E;
  padding: 5rem  0 3rem;
  border-radius: 2.75rem 2.75rem 0 0;
  @media screen and (max-width: map-get(v.$container-breakpoints, "mobile")) {
    padding-bottom: 7.5rem;
  }
  &__grid {
    display: grid;
    grid-template-columns: minmax(22rem, auto) 1fr 1fr;
    grid-column-gap: calc(min(4rem, 5%));
    @media screen and (max-width: 87em) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: map-get(v.$container-breakpoints, "mobile")) {
      grid-template-columns: minmax(15rem, 21rem) 1fr;
      grid-row-gap: 1.5rem;
    }
    @media screen and (max-width: 48em) {
      grid-template-columns: 1fr;
    }
  }
  &__text, &__contact {
    font-size: 0.875rem;
    @media screen and (max-width: 48em) {
      text-align: center;
    }
  }
  &__text {
    color: var(--white-color);
  }
  &__main, &__content {
    @media screen and (max-width: 48em) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__link {
    display: block;
    transition: all 0.3s;
    &:hover {
      color: var(--primary-green-color);
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  &__icon {
    width: 1.625rem;
  }
  &__social {
    @media screen and (max-width: map-get(v.$container-breakpoints, "mobile")) {
      grid-column: 1 / -1;
    }
    position: relative;
  }
  &__contact {
    color: var(--white-color);
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__policy {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    & > * {
      font-size: 0.75rem;
    }
  }
  &__logo {
    margin-top: 2.75rem;
  }
  &__ogrn-inn-kpp {
    margin-top: 1.5rem;
  }
  &__address {
    margin-bottom: 1.5rem;
    & > * {
      font-size: 0.75rem;
    }
  }
  &__phone {
    margin-bottom: 1.875rem;
  }
  &__menu {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &-telegram {
    background-color: #DCEFDE;
    padding: 2rem;
    border-radius: 1.5rem;
    max-width: 22rem;
    @include m.media("last") {
      margin: auto;
    }
    &::after {
      position: absolute;
      width: 2rem;
      height: 4rem;
      border: 2px solid #DCEFDE;
      border-radius: 0 100% 100% 0 / 0 50% 50% 0;
      background: #DCEFDE;
      content: "";
    }
    &__info {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 1.5rem;
    }
    &__heading {
      color: var(--primary-blue-color);
      margin-bottom: 0.35rem;
    }
    &__text {
      color: var(--black-color);
      max-width: 16rem;
      line-height: 1.6rem;
    }
    &__link {
      display: flex;
      justify-content: center;
      position: relative;
      align-items: center;
    }
    &__icon {
      position: absolute;
      left: 0;
      width: 47px;
    }
    &__button {
      background-color: #DCEFDE;
      border: 2px solid var(--primary-orange-color-3);
      border-radius: 3rem;
      box-shadow: none;
      outline: none;
      justify-self: center;
      width: 100%;
      padding-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-block: 0.6rem;
      -webkit-appearance: none;
      &-link {
        color: var(--black-color);
        display: flex;
        align-items: center;
        &:hover {
          color: var(--primary-green-color-darker)
        }
      }
    }
  }
}