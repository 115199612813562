@use "src/assets/scss/abstracts/mixins" as m;

.transactions-stats {
  &__placeholder {
    text-align: center;
    font-size: 0.875rem;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &__element {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background-color: var(--gray-light-color);
      width: calc(100% + 1.5rem);
      left: -0.75rem;
      top: calc(100% + 0.375rem);
      height: 2px;
    }
  }
  &__heading {
    font-size: 0.75rem;
    color: var(--gray-color-3);
  }
  &__amount {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  &__content {
    display: grid;
    grid-template-columns: auto max-content;
    grid-column-gap: 0.25rem;
  }
  &__name {
    max-width: 13rem;
    font-size: 0.875rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__type {
    font-size: 0.75rem;
  }
  &__amount {
    font-size: 0.875rem;
  }
}