@use "../../../../assets/scss/abstracts/variables" as v;
@use "../../../../assets/scss/abstracts/mixins" as m;

.referral-block {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    @media screen and (max-width: 87em) {
      font-size: 1.25rem;
    }
  }
  &__heading {
    @include m.hideOnBreakpoint("last");
    &-mobile {
      @include m.hideOnBreakpoint("last", "min");
    }
  }
  &__open {
    min-width: 2rem;
  }
  &__body {
    padding-block: 0;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 0.875rem;
  }
}