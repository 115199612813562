@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/components/header" as hv;
@use "src/assets/scss/abstracts/mixins" as m;

.lender-navigation {
  @include m.centerLineWithFlex;
  justify-content: space-between;
  border-top: 1px solid var(--gray-light-color);
  @media screen and (max-width: map-get(v.$container-breakpoints, "mobile")) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 4.5rem;
    z-index: 100;
    background-color: var(--white-color);
    padding-inline: 1rem;
    border-radius: hv.$header-border-radius hv.$header-border-radius 0 0;
    box-shadow: 0 0 1.25rem var(--primary-green-color-lighter);
  }
  &__left, &__right {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
  &__left {
    flex: 1;
  }
  &__right {
    @include m.hideOnBreakpoint(map-get(v.$container-breakpoints, "mobile"), 'max');
  }
  &__list {
    @include m.centerLineWithFlex;
    gap: 2rem;
    width: 100%;
    @include m.media("mobile", "max") {
      gap: calc(min(1rem, 1vw));
      justify-content: space-between;
    }
  }
  &__money-links {
    @include m.centerLineWithFlex;
    gap: 0.5rem;
  }
  &__element {
    align-self: stretch;
    @include m.media("mobile", "max") {
      &:nth-last-child(-n + 2) {
        display: none;
      }
    }
  }
  &__link {
    display: block;
    padding-block: 0.75rem;
    color: var(--gray-light-color-2);
    @include m.underscore;
    transition: color 0.3s;
    &:hover {
      color: var(--primary-green-color);
    }
    &.active {
      color: var(--black-color);
      & > .lender-navigation__link-image {
        border-radius: 50%;
        background-color: var(--primary-green-color-lighter);
      }
    }
    @include m.media("mobile") {
      @include m.centerColumnWithFlex;
      gap: 0.25rem;
      font-size: 0.875rem;
    }
    @include m.media("last") {
      font-size: 0.625rem;
      white-space: nowrap;
    }
    &-image {
      @include m.centerWithFlex;
      @include m.box(2rem);
      @include m.hideOnBreakpoint(map-get(v.$container-breakpoints, "mobile"), 'min');
      margin-inline: auto;
    }
  }
  &__money-link {
    @include m.underscore(100%, calc(100% + 0.625rem));
    &.active {
      background-color: v.$white-color;
      color: v.$black-color;
    }
  }
}