@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.my-money {
  &-section {
    padding-bottom: 9rem;
    margin: 0 12rem;
    @include m.media("first") {
      margin: 0;
    }
    @include m.media("last") {
      padding-bottom: 4rem;
    }
    position: relative;
  }

  &__stats {
    display: grid;
    grid-template-columns: minmax(17rem, 1fr) minmax(6rem, 10rem) repeat(4, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas:
            "portfolio portfolio portfolio portfolio balance balance"
            "rest rest rest rest balance balance";
    grid-gap: 3.5rem 1rem;
    @include m.media("first") {
      grid-template-columns: repeat(6, 1fr);
    }
    @include m.media("second") {
      grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content);
      grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
      grid-template-areas:
            "portfolio balance"
            "rest transactions";
      grid-gap: 0.625rem;
    }
    @include m.media("mobile") {
      grid-template-columns: 1fr;
      grid-template-areas:
            "balance"
            "portfolio"
            "rest";
      grid-gap: 2rem;
    }
    @include m.media("last") {
      grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
    }
  }

  &__balance {
    grid-area: balance;
  }

  &__download {
    position: absolute;
    top: 8.5rem;
    right: 2rem;
  }
}