@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.information {
  @include m.box(0.875rem);
  border-radius: 50%;
  background-color: var(--gray-light-color-2);
  @include m.centerWithFlex;
  position: relative;
  cursor: pointer;

  &__answer {
    cursor: initial;
    position: absolute;
    z-index: 1;
    background: var(--white-color);
    box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 0.5rem;
    min-width: 18rem;
    left: 6.5rem;
    transform: translateX(-50%);
    bottom: 1.875rem;
    visibility: hidden;
    opacity: 0;
    @include m.media("second") {
      left: 0.5rem;
      min-width: 15rem;
    }
    @include m.media("mobile") {
      left: 0;
      padding: 0.5rem;
    }

    &::after {
      content: "";
      position: absolute;
      left: 2.5rem;
      bottom: -0.375rem;
      background-image: url("../../../../assets/images/blact-rectangle.svg");
      height: 0.375rem;
      width: 0.75rem;
      @include m.media("second") {
        left: 50%;
        transform: translateX(-50%);
      }
      @include m.media("mobile") {
        left: 55%;
      }
    }

    &--enter {
      visibility: hidden;
      opacity: 0;

      &-active {
        opacity: 1;
        visibility: visible;
        transition: all 0.3s;
      }

      &-done {
        opacity: 1;
        visibility: visible;
      }
    }

    &--exit {
      opacity: 1;
      visibility: visible;

      &-active {
        opacity: 0;
        visibility: visible;
        transition: all 0.3s;
      }

      &-done {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &__text {
    color: var(--black-color);
    font-size: 0.75rem;
    line-height: 140%;
  }

  &__position_bottom {
    top: 1.5rem;
    left: -10rem;
    bottom: auto;

    &::after {
      bottom: auto;
      top: -0.375rem;
      transform: rotate(180deg);
      left: 22.5rem;
    }
  }

  &__position_left {
    left:-5rem;

    &::after {
      left: 14rem;
    }
  }
}