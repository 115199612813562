@use "src/components/pages/detailed-project/styles/mixins" as dm;

.project-stages {
  @include dm.mountAnimation;
}

.project-stage {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-block: 0.5rem;
  &__left {
    display: flex;
    gap: 1rem;
  }
  &__name {
    font-size: calc(0.875rem + 1px);
    margin-bottom: 0.625rem;
  }
  &__content {
    font-size: 0.875rem;
    color: var(--gray-color-3);
    line-height: 1.125rem;
  }
  &__date {
    line-height: 1.25rem;
    font-size: 0.875rem;
    color: var(--gray-color-3);
  }
}