@use "../../../../assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;


.survey-confirmation-modal {
  &__container {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    @include m.media("last") {
      margin: 0.8rem;
    }
  }
  &__button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  &__button-yes {
    min-width: 6rem;
    @include m.media("last") {
      min-width: 5rem;
      font-size: 0.8rem;
    }
  }
  &__button-cancel {
    color: v.$black-color;
    background: v.$gray-color;
    min-width: 6rem;
    @include m.media("last") {
      min-width: 5rem;
      font-size: 0.8rem;
    }
  }
  &__title {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.5rem;
    @include m.media("last") {
      font-size: 1rem;
      margin-bottom: 1rem;

    }
  }
}