@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.scroll-top-button {
  @include m.centerWithFlex;
  @include m.box(3rem);
  background-color: transparent;
  border: 2px solid var(--primary-yellow-color);
  border-radius: 50%;
  position: fixed;
  inset: auto 3rem 3rem auto;
  z-index: 50;
  @media screen and (max-width: map-get(v.$container-breakpoints, "mobile")) {
    inset: auto 1rem 6rem auto;
  }
}