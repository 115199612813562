@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

$grid-columns: 1fr 1.5fr minmax(2rem, 1fr) 1fr;

$last-breakpoint: 41.25em;

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: "debt investment date money";
  grid-column-gap: 2.5rem;
  @include m.media("first") {
    grid-template-columns: $grid-columns;
  }
  @include m.media("second") {
    grid-template-columns: $grid-columns;
    grid-column-gap: 1rem;
  }
  @include m.media("mobile") {
    grid-template-columns: $grid-columns;
    grid-template-rows: minmax(2rem, max-content) max-content;
    grid-template-areas:
            "debt investment date money";
    grid-gap: 0.5rem 0;
  }
  @include m.media($last-breakpoint) {
    grid-template-columns: $grid-columns;
    grid-column-gap: 1rem;
  }
}
.referral-transaction {
  @include gridValues;
  font-size: 0.875rem;
  padding-inline: 1.5rem;
  padding-block: 0.75rem 2.25rem;
  position: relative;
  @include m.media("second") {
    padding-block: 2.25rem 1.5rem;
  }
  @include m.media("mobile") {
    padding-block: 0.5rem 0;
    padding-inline: 1rem;
    cursor: pointer;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--gray-light-color);
    top: 100%;
  }
  &__column {
    @include m.media("mobile", "min") {
      display: flex;
      align-items: center;
    }
  }
  &--incoming {
    color: var(--primary-green-color);
  }
  &--outgoing {
    color: var(--red-color);
  }
  &__header {
    margin-bottom: 0;
  }
  &__debt {
    grid-area: debt;
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    position: relative;
    @include m.media('second') {
      grid-template-columns: 1fr;
    }
    @include m.media("mobile", "min") {
      gap: 0.5rem;
    }
    @include m.media("mobile") {
      flex-direction: column-reverse;
    }
  }
  &__investment {
    grid-area: investment;
  }
  &__date {
    grid-area: date;
  }
  &__money {
    grid-area: money;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    @include m.media($last-breakpoint) {
      justify-content: flex-end;
    }
  }
  &__id {
    color: var(--gray-color-3);
    @include m.media("mobile") {
      position: initial;
    }
  }
  &__type {
    grid-area: type;
    display: flex;
    @include m.media("mobile") {
      align-items: center;
    }
    @include m.media("mobile") {
      margin-right: 0.25rem;
    }
  }
  &.active {
    .transaction__description {
      @include m.media("mobile") {
        max-height: fit-content;
      }
    }
    .transaction__investment, .transaction__date {
      @include m.media($last-breakpoint) {
        max-height: fit-content;
      }
    }
  }
  &__text {
    @include m.media("mobile") {
      font-size: 0.75rem;
    }
  }
  &__project {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include m.media(41.25em) {
      max-width: 11rem;
    }
  }
  &__investment, &__date {
    @include m.media($last-breakpoint) {
      overflow: hidden;
      max-height: 0;
    }
  }
  &__description {
    grid-area: description;
    @include m.media("mobile") {
      grid-column: 1 / -1;
      overflow: hidden;
      max-height: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-close {
      @include m.box(2rem);
      background-color: var(--gray-color-2);
      border-radius: 50%;
      cursor: pointer;
      @include m.centerWithFlex;
      @include m.hideOnBreakpoint(62em, "min");
    }
  }
}
