@use "src/assets/scss/abstracts/mixins" as m;

.block-navigation {
  &__list {
    display: flex;
    align-items: stretch;
    gap: 2rem;
    scrollbar-width: none;
    @include m.media("second") {
      gap: 1rem;
    }
  }
}