@use "src/assets/scss/abstracts/mixins" as m;

.projects-filter-modal {
  padding: 1rem;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  &__close {
    position: relative;
    inset: initial;
    background: url("../../../../../src/assets/images/svg/arrow-l.svg") center no-repeat var(--primary-green-color-lighter);
    @include m.box(2rem);
    &::after, &::before {
      display: none;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__heading {
    font-weight: 700;
    font-size: 1rem;
  }
  &__title {
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
  }
  &__classes {
    margin-bottom: 0.625rem;
  }
  &__head {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  &__slider {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
  &__filter-clear {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    & .tertiary-button {
      font-size: 1em;
      padding-block: 0.25em;
    }
  }
}