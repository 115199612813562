@use "src/assets/scss/abstracts/mixins" as m;

$grid-columns: 23% repeat(3, 10%) 42%;
$grid-columns-first: 23% repeat(3, 10%) 42%;
$grid-columns-second: 20% repeat(3, 9%) 50%;
$grid-columns-mobile: 30% repeat(3, 20%);
$grid-columns-last: 48% 48%;

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas:
                "categoryContainer sum forecast term statusContainer"
                "primary sum forecast term statusContainer";
  grid-column-gap: 1.25rem;
  align-items: center;
  @include m.media("first") {
    grid-template-columns: $grid-columns-first;
    grid-column-gap: 1rem;
  }
  @include m.media("second") {
    grid-template-columns: $grid-columns-second;
    grid-column-gap: 0.6rem;
    grid-template-areas:
                "categoryContainer sum forecast term statusContainer"
                "primary sum forecast term statusContainer";
  }
  @include m.media("mobile") {
    padding: 1rem;
    grid-template-columns: $grid-columns-mobile;
    grid-row-gap: 0.6rem;
    grid-template-areas:
                "categoryContainer sum forecast term"
                "primary sum forecast term"
                "statusContainer statusContainer statusContainer statusContainer";
  }
  @include m.media("last") {
    grid-template-columns: $grid-columns-last;
    align-items: flex-start;
    grid-template-areas:
                "categoryContainer categoryContainer"
                "primary primary"
                "sum forecast"
                "term forecast"
                "statusContainer statusContainer";
  }
}