@use "src/assets/scss/abstracts/mixins" as m;

.projects-sub-filter {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include m.media("first") {
    padding: 0.5rem 1rem;
  }
  @include m.media("second") {
    align-items: flex-start;
    padding-inline: 0.5rem;
  }
}