@use "../../../../assets/scss/abstracts/variables" as v;
@use "../../../../assets/scss/abstracts/mixins" as m;

.profile-menu {
  display: flex;
  justify-content: space-between;
  gap: 2.5rem;
  cursor: pointer;
  position: relative;
  z-index: 5;
  &__user {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  &__image {
    @include m.box(2.5rem);
    border-radius: 50%;
  }
  &__toggle {
    position: relative;
    &-button {
      @include m.box(1.5rem);
      @include m.centerWithFlex;
      background-color: var(--gray-light-color);
      border-radius: 50%;
    }
    &-image {
      @include m.box(0.625rem);
    }
  }
}

.profile-actions {
  background-color: var(--white-color);
  padding-top: 0.75rem;
  clip-path: polygon(0 0.75rem, 38% 0.75rem, 50% 0, 62% 0.75rem, 100% 0.75rem, 100% 100%, 0 100%);
  border-radius: 1rem;
  @media screen and (max-width: calc(#{v.$container-max-width} + 9rem)) {
    clip-path: polygon(0 0.75rem, 72% 0.75rem, 85% 0, 100% 0.75rem, 98% 100%, 100% 100%, 0 100%);
  }
  &__wrapper {
    position: absolute;
    @include m.centerLineWithPositioning;
    top: 2rem;
    filter: drop-shadow(0 0 4px rgba(black, 0.25));
    @media screen and (max-width: calc(#{v.$container-max-width} + 9rem)) {
      left: -3.5rem;
    }
  }
  &__container {
    padding: 0.65rem 1.25rem;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(v.$gray-color, 50%);
      padding-block: 1.5rem;
    }
  }
  &__list {
    list-style-type: none;
  }
  &__element {
    white-space: nowrap;
    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }
  &__link {
    display: inline-block;
    width: 100%;
    color: var(--black-color);
    font-size: 85%;
    &:hover {
      color: var(--primary-green-color);
    }
  }
}