@use "../../../../assets/scss/abstracts/variables" as v;
@use "../../../../assets/scss/abstracts/mixins" as m;

$grid-columns: 1fr 1.5fr 1fr 1fr;

$last-breakpoint: 41.25em;

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: "debt investment date money";
  grid-column-gap: 2.5rem;
  @include m.media("first") {
    grid-template-columns: $grid-columns;
  }
  @include m.media("second") {
    grid-template-columns: $grid-columns;
    grid-column-gap: 1rem;
  }
  @include m.media("mobile") {
    grid-template-columns: $grid-columns;
    grid-template-rows: minmax(2rem, max-content) max-content;
    grid-template-areas:
            "debt investment date money";
    grid-gap: 0.5rem 0;
  }
  @include m.media($last-breakpoint) {
    grid-template-columns: $grid-columns;
    grid-column-gap: 1rem;
  }
}

.referral-transactions-header {
  align-items: center;
  border-radius: 0.75rem;
  background-color: #fbfbfb;
  color: var(--gray-color-3);
  font-size: 0.75rem;
  padding: 1rem;
  @include gridValues;
}

.referral-transactions-section {
  max-width: 87rem;
}
.referral-transactions {
  &-body {
    border-radius: 0.75rem;
  }
  &-header {
    &__item:first-child {
      display: flex;
      gap: 0.5rem;
      @include m.media('second') {
        flex-direction: column;
      }
    }
  }
  &-block-header {
    padding-inline: 1.5rem;
    @include m.media("mobile") {
      padding-left: 0;
    }
    @include m.media($last-breakpoint) {
      padding: 0.75rem;
    }
    & .common-icon {
      @include m.media($last-breakpoint) {
        @include m.box(1.75rem);
        & > svg {
          @include m.box(50%);
        }
      }
    }
  }
  &-body {
    padding-block: 0 4rem;
    @include m.media("mobile") {
      padding-bottom: 2rem;
    }
  }
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  &__list {
    @include m.media("mobile", "min") {
      padding-top: 1rem;
    }
  }
}


