@use "src/assets/scss/abstracts/mixins" as m;

.primary-button {
  @include m.centerWithFlex;
  font-size: 1rem;
  color: var(--white-color);
  border-radius: 0.625rem;
  padding: 0.5em 0.75em;
  text-align: center;
  border: none;
  transition: 300ms;
  &:hover {
    color: var(--white-color);
  }
  &--expanded {
    width: 100%;
  }
  &--large {
    padding: 0.75em 1.25em;
  }
  &--green {
    background-color: var(--primary-green-color);
    &:hover {
      background-color: var(--primary-green-color-darker);
    }
  }
  &--blue {
    background-color: var(--primary-blue-color);
    &:hover {
      background-color: var(--primary-blue-color-darker);
    }
  }
  &--gray {
    background-color: var(--gray-color);
    &:hover {
      background-color: var(--gray-dark-color);
    }
  }
  &--disabled {
    background-color: var(--gray-light-color);
    cursor: initial;
    pointer-events: none;
  }
}