@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.input-sms-code {
  position: relative;
  &.active {
    & > .input-sms-code__label {
      display: none;
    }
    & > .input-sms-code__input {
      border-color: var(--primary-green-color);
    }
  }
  &__label {
    position: absolute;
    @include m.centerColumnWithPositioning;
    color: var(--gray-color-3);
    left: 1rem;
    pointer-events: none;
    transition: v.$default-transition;
    font-size: 1rem;
  }
  &__input {
    text-align: center;
    width: 100%;
    min-height: 4rem;
    background-color: var(--gray-light-color);
    border: 2px solid var(--gray-light-color);
    border-radius: 0.625rem;
    font-size: 2.5rem;
    &:focus {
      outline: none;
    }
  }
}