@use "src/assets/scss/abstracts/mixins" as m;

.identification-file-upload {
  &__text {
    font-size: 0.875rem;
    white-space: nowrap;
  }
  &__block {
    border: 2px dashed var(--gray-color-2);
    width: 100%;
    position: relative;
    padding: 1.25rem 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__approved {
    position: absolute;
    top: 0.75rem;
    bottom: 0.75rem;
    @include m.box(1.5rem);
    border-radius: 50%;
    background-color: var(--primary-green-color);
    @include m.centerWithFlex;
  }
  &__placeholder {
    & label {
      color: var(--primary-green-color);
      cursor: pointer;
    }
    @include m.media("mobile") {
      max-width: 10rem;
      font-size: 0.875rem;
    }
  }
  &__file-placeholder {
    font-size: 0.75rem;
  }
  &__element {
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  &__delete {
    @include m.box(0.625rem);
    @include m.centerWithFlex;
    cursor: pointer;
  }
  &__file {
    & input[type="file"] {
      display: none;
    }
  }
}