@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.my-money {
  &__block-header {
    min-height: 5.625rem;
    padding-block: 1rem;
    @include m.media("second") {
      min-height: 5rem;
    }
  }
  &__transactions {
    grid-area: transactions;
    @include m.media("mobile") {
      display: none;
    }
    &-body {
      padding-block: 0.75rem;
    }
    &-footer {
      padding: 0.25rem;
      & > * {
        border-radius: 0 0 0.5rem 0.5rem;
        padding-block: 0.5rem;
      }
    }
  }
  &__heading {
    font-size: 1.25rem;
    &--main {
      font-size: 1.5rem;
      @include m.media("second") {
        font-size: 1.25rem;
      }
      @include m.media("last") {
        font-size: 1rem;
      }
    }
  }
}
