@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.deposit-container {
  display: flex;
  flex-direction: column;
  gap: 4.25rem;
  @include m.media("second", "min") {
    max-width: 58.5rem;
  }
}

.deposit {
  &-section {
    @include m.media("mobile", "min") {
      padding-bottom: 12.5rem;
    }
  }
  &__text {
    font-size: 1rem;
    max-width: 52.25rem;
    margin-block: 0.5rem 2.25rem;
    line-height: 1.5rem;
  }
  &__data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
    @include m.media("last", "max") {
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
    }
  }
  &__requisites {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: stretch;
  }
  &__requisite {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m.media("mobile", "max") {
      gap: 0.5rem;
    }
    @include m.media("last", "max") {
      align-items: flex-end;
    }
    & .copy-to-clipboard {
      @include m.media("last", "max") {
        @include m.box(1.5rem);
        & > img {
          width: 40%;
        }
      }
    }
  }
  &__heading {
    font-size: 0.875rem;
    color: var(--gray-light-color-2);
  }
  &__info {
    display: grid;
    grid-template-columns: 6rem 1fr;
    grid-column-gap: 1rem;
    @include m.media("mobile", "max") {
      grid-template-columns: 4rem 1fr;
    }
    @include m.media("mobile", "max") {
      font-size: 0.75rem;
      grid-template-columns: 1fr;
      grid-template-rows: minmax(min-content, max-content);
    }
  }
}

.deposit-purpose {
  background-color: var(--pink-color);
  position: relative;
  border-radius: 1.25rem;
  padding: 1.5rem 2rem 2.25rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  box-shadow: v.$default-box-shadow;
  &__title {
    display: flex;
    justify-content: space-between;
  }
  &__heading {
    font-size: 1.125rem;
  }
  &__text {
    font-size: 0.875rem;
    line-height: 1.125rem;
    max-width: 21rem;
  }
  &__block {
    background-color: var(--white-color);
    @include m.centerLineWithFlex;
    justify-content: space-between;
    border-radius: 0.625rem;
    padding: 1.25rem 1.125rem 1.25rem 2rem;
    @include m.media("mobile", "max") {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  &__id {
    font-size: 1.25rem;
    white-space: nowrap;
  }
}

.deposit-qr {
  &__title {
    text-align: center;
  }
  &__code {
    margin: 1rem auto;
    @include m.centerWithFlex;
    @include m.box(15rem);
    padding: 1.125rem 0.75rem;
    border-radius: 0.625rem;
    background-color: var(--white-color);
  }
}

.warning {
  @include m.box(0.875rem);
  border-radius: 50%;
  background-color: var(--gray-light-color-2);
  @include m.centerWithFlex;
  position: relative;
  cursor: pointer;
  &__answer {
    cursor: initial;
    position: absolute;
    z-index: 1;
    border-radius: 1.25rem;
    background-color: v.$gray-color-2;
    padding: 28px 40px 45px 24px;
    min-width: 29rem;
    left: 4rem;
    bottom: -6.5rem;
    visibility: hidden;
    opacity: 0;
    @include m.media("first") {
      min-width: 19rem;
    }
    @include m.media("second") {
      left: -6.5rem;
      transform: translateX(-50%);
      bottom: 3rem;
      padding: 14px 20px 22px 12px
    }

    &::after {
      content: "";
      position: absolute;
      left: -1.75rem;
      bottom: 5rem;
      transform: rotate(90deg);
      background-image: url("../../../../../assets/images/gray-rectangle.svg");
      height: 20px;
      width: 40px;
      @include m.media("second") {
        transform: rotate(0deg);
        left: 15.2rem;
        bottom: -1rem;
      }
    }
    &--enter {
      visibility: hidden;
      opacity: 0;
      &-active {
        opacity: 1;
        visibility: visible;
        transition: all 0.3s;
      }
      &-done {
        opacity: 1;
        visibility: visible;
      }
    }
    &--exit {
      opacity: 1;
      visibility: visible;
      &-active {
        opacity: 0;
        visibility: visible;
        transition: all 0.3s;
      }
      &-done {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &__min-sum {
    padding-top: 1.5rem;
  }
  &__text {
    color: v.$black-color;
    font-size: 1rem;
    line-height: 140%;
  }
}