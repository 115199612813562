@use "../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../assets/scss/abstracts/variables" as v;

.copy-to-clipboard {
  @include m.box(2rem);
  background-color: var(--primary-green-color-lighter);
  color: var(--black-color);
  cursor: pointer;
  border-radius: 0.5rem;
  @include m.centerWithFlex;
  gap: 0.5rem;
  transition: all 0.3s;
  &:active {
    background-color: rgba(v.$primary-green-color, 0.5);
  }
  &--full {
    width: fit-content;
    white-space: nowrap;
    padding: 1.25rem 1.15rem;
  }
}