@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;
@use "../grid" as gr;

$grid-areas: primary category investment loan term simple yearly transactions stages status actual-payment;

@mixin columnUnderscore($width: 100%, $height: 0.25rem) {
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: $width;
    height: $height;
    background-color: var(--gray-light-color);
    z-index: 2;
  }
}

.archived-project {
  @include gr.gridValues;
  padding: 1.5rem;
  position: relative;
  transition: v.$default-transition;
  background-color: var(--white-color);
  @include m.media("mobile") {
    padding-block: 1rem;
  }
  @include m.media("mobile", "min") {
    &:hover {
      background-color: var(--gray-light-color);
    }
  }
  @include m.media(27em) {
    grid-column-gap: 1rem ;
    grid-template-areas:
        "primary primary category"
        "investment loan term"
        "simple yearly status"
        "transactions stages actual-payment";
  }
  @include m.media("last") {
    grid-template-columns: 2fr 2fr 1fr;
  }
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }
  &__link {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
  &__column {
    font-size: 0.875rem;
    @include m.centerLineWithFlex;
    position: relative;
    @include m.media("second") {
      font-size: 0.75rem;
    }
    @include m.media("mobile") {
      font-size: 0.875rem;
      flex-direction: column;
      padding-block: 0.375rem;
      justify-content: flex-start;
    }
    @include m.media("last") {
      justify-content: space-between;
    }
  }
  &__category, &__investment, &__loan, &__term, &__simple, &__yearly, &__status {
    @include m.media("second") {
      justify-content: center;
    }
    @include m.media("last") {
      align-items: flex-start;
    }
  }
  &__category {
    @include m.media("mobile") {
      align-items: flex-end;
    }
  }
  &__name {
    @include m.media("second") {
      @include m.lineClamp(3);
    }
    @include m.media("mobile") {
      @include m.lineClamp(2);
    }
    @include m.media("last") {
      @include m.lineClamp(1);
    }
  }
  &__primary {
    @include m.media("mobile") {
      align-items: flex-start;
      justify-content: flex-end;
    }
  }
  &__title {
    position: relative;
    width: 100%;
  }
  &__status, &__stages {
    @include m.media("mobile") {
      align-items: flex-end;
    }
  }
  &__transactions {
    @include m.media("mobile") {
      align-items: flex-start;
    }

  }
  &__term, &__transactions {
    @include m.media("last") {
      align-items: flex-end;
    }
  }
  &__stages, &__status {
    @include m.media("last") {
      align-items: flex-start;
    }
  }
  @include m.media(27em) {
  &__stages, &__transactions {
    align-items: flex-start;
    &::after {
      width: 0 !important;
    }
    }
  }
  @include m.media(27em) {
  &__status {
      align-items: flex-end;
    &::after {
        width: 100% !important;
      }
    }
  }
  // Underscore logic

  &:not(:last-child) {
    @include columnUnderscore;
  }
  @include m.media("mobile") {
    &:not(:last-child) {
      &::after {
        height: 0.375rem;
      }
    }
    &__primary, &__category, &__investment, &__loan, &__term, &__simple, &__yearly, &__status, &__actual-payment {
      @include columnUnderscore(calc(100% + 0.5rem), 2px);
    }
    &__category, &__status {
      &::after {
        width: 100%;
      }
    }
  }
  @include m.media("last") {
    &__status {
      &::after {
        width: 0;
      }
    }
    &__transactions, &__term {
      @include columnUnderscore(100%, 2px)
    }
  }
}