@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.greetingContent {
	position: relative;
	display: flex;
	@include m.media("mobile") {
		background: #F3F3F3 url('../../../assets/images/svg/default-background.svg') no-repeat 50% 30%;
		justify-content: flex-end;
		height: 80%;
	}
	flex-direction: column;
	justify-content: space-between;
	padding: 2rem;
	height: 100%;
	width: auto;
	color: #212529;
	border-radius: 10px;
}

.welcomeTitle {
	@include m.media("mobile") {
		font-size: 20px;
		text-align: center;
	}
	font-size: 2rem;
	font-weight: bold;
	line-height: 1.2;
	margin-bottom: 1rem;
}

.text-gray-3 {
	@include m.media("mobile") {
		display: none;
	}
	font-size: 22px;
	color: #757575;
}

.boldText {
	font-weight: 500;
}
