.d-flex {
  display: flex !important;
}

$align-values: center, stretch, start, end;

@each $value in $align-values {
  .align-items-#{$value} {
    align-items: #{$value} !important;
  }
  .align-self-#{$value} {
    align-self: #{$value} !important;
  }
}

$justify-values: (
        "start": "start",
        "end": "end",
        "center": "center",
        "between": "space-between",
        "around": "space-around"
);

@each $key, $value in $justify-values {
  .justify-content-#{$key} {
    justify-content: #{$value} !important;
  }
}

$flex-directions: row, column, row-reverse, column-reverse;

@each $key in $flex-directions {
  .flex-#{$key} {
    flex-direction: #{$key};
  }
}

$wrap: wrap, nowrap;

@each $key in $wrap {
  .flex-#{$key} {
    flex-wrap: #{$key};
  }
}