@use "../../../../assets/scss/abstracts/mixins" as m;

.form__input--black {
  color: var(--black-color) !important;
}
.input-number {
  &__container {
    background-color: var(--gray-light-color);
    border: none;
    min-height: 4rem;
  }
  &__left {
    flex: 1;
  }
  &__text, &__code {
    color: var(--gray-color);
  }
  &__text {
    font-size: 85%;
  }
  &__code {
    font-size: 115%;
    line-height: 100%;
    margin-top: 0.01rem;
  }
  &__input {
    background-color: var(--gray-light-color);
    padding: 0;
    line-height: 100%;
  }
  &--valid {
    color: var(--black-color);
  }
}