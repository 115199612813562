@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.profile-controls {
  margin-block: 10rem;
  width: calc(min(90%, 55rem));
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.profile-credentials {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.profile-control {
  background-color: var(--white-color);
  box-shadow: 0 0 1rem 1px rgba(v.$black-color, 0.25);
  border-radius: 1rem;
  padding: 1.25rem;
  &__background {
    position: fixed;
    inset: 0;
    z-index: 15;
    background-color: rgba(v.$white-color, 0.75);
    overflow-y: scroll;
    scrollbar-width: none;
  }
  &__close {
    position: fixed;
    inset: 2rem 2rem auto auto;
  }
  &__avatar {
    display: flex;
    justify-content: center;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.75rem;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 1.5rem;
    padding-top: 1.5rem;
    &-small {
      @include m.media("mobile", "max") {
        grid-column: span 2;
      }
      @include m.media("last", "max") {
        grid-column: 1 / -1;
      }
    }
    &-row {
      grid-column: 1 / -1;
    }
  }
}