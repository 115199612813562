@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.detailed-project-invest {
  grid-row: 1 / 5;
  grid-column: 2 / 5;
  @include m.media("mobile") {
    grid-row: 2 / 5;
    grid-column: 1 / 1;
  }
  &__container {
    box-shadow: v.$default-box-shadow;
    border-radius: 1.25rem;
    background-color: var(--white-color);
    padding: 1.5rem;
    @include m.media("first") {
      max-width: 22.5rem;
    }
    @include m.media("second") {
      max-width: 18rem;
    }
    @include m.media("mobile") {
      max-width: 100vw;
      padding-block: 0.75rem;
    }
  }
  &__progress {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  &__deadline {
    font-size: 0.75rem;
    color: var(--gray-color-3);
    text-align: center;
  }
  &__main {
    @include m.media("mobile") {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas:
              "submit"
              "money";
    }
  }
  &__money-container {
    text-align: end;
  }
  &__money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    @include m.media("second") {
      font-size: 0.75em;
      margin-bottom: 0.5rem;
    }
    @include m.media("mobile", "min") {
      margin-bottom: 0.75rem;
    }
    &--additional {
      color: var(--gray-light-color-2);
    }
    &__error {
      color: red;
    }
  }
  &__input {
    margin-bottom: 0.75rem;
    @include m.media("second") {
      & .input-money {
        font-size: 1.25rem;
        &__container {
          padding-inline: 0.75rem;
        }
      }
    }
    &-additional {
      grid-column: 1 / -1;
      font-size: 0.875rem;
      margin-top: 1rem;
      @include m.media("second") {
        margin-bottom: 0.5rem;
      }
      @include m.media("mobile", "min") {
        margin-bottom: 0.75rem;
      }
    }
  }
  &__ai-container {
    @include m.media("mobile") {
      padding: 1.5rem;
    }
  }
  &__toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.875rem;
    margin-bottom: 1.25rem;
    @include m.media("mobile") {
      margin-bottom: 0.8rem;
    }
  }

  &__toggle-title {
    font-size: var(--font-size-l);
    font-weight: 500;
    color: var(--gray-color-3);
    &.active {
      color: var(--primary-green-color);
    }
    @include m.media("mobile") {
      font-size: var(--font-size-s);
    }
  }

  &__date-ai {
    font-size: var(--font-size-l);
    font-weight: 500;
    margin-bottom: 1.25rem;
    @include m.media("mobile") {
      font-size: var(--font-size-sm);
    }
  }
  &__settings-ai {
    font-size: var(--font-size-m);
    font-weight: 500;
    @include m.media("mobile") {
      font-size: var(--font-size-sm);
    }
  }

  &__input-title {
    margin-top: 1rem;
    @include m.media("mobile") {
      font-size: var(--font-size-sm);
    }
  }
  &__button-save {
    margin-top: 1rem;
    @include m.media("mobile") {
      font-size: var(--font-size-s);
    }
  }
}
.not-verified-info {
  position: absolute;
  inset: 17.8rem 6.5rem auto auto;
  @include m.media('second') {
    inset: 18.2rem 4rem auto auto;
  }
  &--mobile {
    position: absolute;
    inset: 3.2rem 15% auto auto;
    @include m.hideOnBreakpoint("mobile", "min");
    @include m.media("last") {
      inset: 3.2rem 6% auto auto;
    }
  }
}