@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.notifications {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  &__container {
    display: grid;
    grid-template-columns: 1fr 28.5rem;
    grid-gap: 1.5rem;
    @include m.media("second") {
      grid-template-columns: 1fr 20rem;
    }
    @include m.media("mobile") {
      grid-template-columns: 1fr;
    }
  }
}