@use "../../../../../../assets/scss/abstracts/variables" as v;
@use "../../../../../../assets/scss/abstracts/components/header" as hv;
@use "../../../../../../assets/scss/abstracts/mixins" as m;
@use "../DocumentsElements/grid" as gr;


.documents {
  @include m.centerColumnWithFlex;

  &__container {
    margin-top: 2rem;
    width: 50%;
    @include m.media("first") {
      width: 55%;
    }
    @include m.media("second") {
      width: 70%;
    }
    @include m.media("mobile") {
      width: 80%;
    }
    @include m.media("last") {
      width: 90%;
    }
  }

  &__filtersContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-end;
    margin: 1rem 6rem 1rem 0;
    @include m.media("last") {
      margin: 0;
    }
  }
}

