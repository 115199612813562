@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;
@use "src/components/pages/transactions/grid" as gr;

.transactions-header {
  @include gr.gridValues;
}

.transactions {
  &-header {
    &__item:first-child {
      display: flex;
      gap: 0.5rem;
      @include m.media('second') {
        flex-direction: column;
      }
    }
  }
  &-block-header {
    padding-inline: 1.5rem;
    @include m.media("mobile") {
      padding-left: 0;
    }
    @include m.media(gr.$last-breakpoint) {
      padding: 0.75rem;
    }
    & .common-icon {
      @include m.media(gr.$last-breakpoint) {
        @include m.box(1.75rem);
        & > svg {
          @include m.box(50%);
        }
      }
    }
  }
  &-body {
    padding-block: 0 4rem;
    @include m.media("mobile") {
      padding-bottom: 2rem;
    }
  }
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  &__list {
    @include m.media("mobile", "min") {
      padding-top: 1rem;
    }
  }
  &__link {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
}

.transactions-filter {
  &__header {
    display: flex;
    margin-bottom: 1rem;
    @include m.media(gr.$last-breakpoint, "min") {
      gap: 2rem;
      align-items: center;
    }
    @include m.media(gr.$last-breakpoint) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}