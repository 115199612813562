@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.rotated-opener {
  @include m.box(2rem);
  border-radius: 50%;
  cursor: pointer;
  transition: v.$default-transition;
  transform: rotate(180deg);
  background:
          url("../../../../../src/assets/images/svg/green-arrow-up.svg")
          no-repeat
          center
          var(--primary-green-color-lighter);
  background-size: 50%;
  &.active {
    transform: rotate(0);
  }
}