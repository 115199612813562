@use "src/assets/scss/abstracts/mixins" as m;

.avatar-modal {
  padding: 0.25rem;
  &__header {
    background-color: var(--gray-light-color);
    border-radius: 1.25rem 1.25rem 0 0;
    font-size: 1.5rem;
    padding: 1.125rem 1.75rem;
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 3.5rem 2rem 2rem;
  }
  &__types {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  &__type {
    @include m.centerWithFlex;
    @include m.box(9.5rem);
    border-radius: 50%;
    border: 3px solid var(--gray-light-color);
    cursor: pointer;
    &.active {
      border-color: var(--primary-green-color);
    }
  }
  &__image {
    @include m.box(80%);
    border-radius: 50%;
    overflow: hidden;
    & > img {
      @include m.box(100%);
    }
  }
  &__button {
    padding-block: 1.25rem;
  }
}