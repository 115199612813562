@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.detailedProjectMain {
  box-shadow: v.$default-box-shadow;
  border-radius: 1.25rem;
  padding: 1.5rem;
  background-color: var(--white-color);
  grid-column: 1 / 2;

  &__code {
    font-size: var(--font-size-s);
    color: var(--gray-color-3);
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    @include m.media("mobile") {
      flex-direction: column;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    @include m.media("mobile") {
      margin-bottom: 1rem;
      font-size: var(--font-size-s);
    }
  }

  &__classContainer {
    display: flex;
  }

  &__class {
    background-color: var(--gray-light-color);
    color: var(--primary-green-color);
    font-weight: 400;
    font-size: var(--font-size-sm);
    border-radius: 1.25rem;
    padding: 0.2rem 1rem;
    margin-right: 1rem;
    z-index: 1;
  }

  &__project {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include m.media("mobile") {
      flex-direction: column;
    }
  }

  &__name {
    font-size: var(--font-size-l);
    font-weight: 800;
    margin-bottom: 0.875rem;
    @include m.media("mobile") {
      font-size: var(--font-size-m);
    }
  }

  &__dateContainerLayout {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;

  }

  &__dateContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
            "title"
            "value";
    gap: 0.8rem;
  }

  &__dateTitle {
    font-size: var(--font-size-sm);
    color: var(--gray-color-3);
    grid-area: title;
    margin-bottom: 0;

  }

  &__dateValue {
    font-size: var(--font-size-m);
    font-weight: 400;
    grid-area: value;
    margin-bottom: 0.5rem;
  }

  &__info {
    font-size: var(--font-size-sm);
    max-width: 19.125rem;
    color: var(--gray-color-3);
    @include m.media("mobile") {
      font-size: var(--font-size-s);
    }
  }

  &__oredrsSum {
    max-width: 100%;
    min-height: 3rem;
    background-color: var(--gray-light-color);
    padding: 1rem 1.25rem;
    border-radius: 0.625rem;
    color: var(--gray-color-3);

    &__text {
      margin-right: 3rem;
      @include m.media("second") {
        margin-right: 0;
      }
    }

    @include m.media("mobile") {
      margin-top: 1rem;
    }
  }

  &__information__answer {
    max-height: 7rem;
    min-width: 25rem;
    @include m.media("second") {
      min-width: 1rem;
      left: -2rem;
      max-height: 15rem;
    }
    @include m.media("mobile") {
      left: -3rem;
      min-width: 10rem;
      max-height: 25rem;
    }

    &::after {
      @include m.media("second") {
        left: 50%;
      }
      @include m.media("mobile") {
        left: 81%;
      }
    }
  }

  &__information__answer__error {
    left: -3rem;
    min-width: 10rem;

    &::after {
      left: 8.1rem;
    }
  }
}

.detailedProjectMainIncasso {
  display: flex;
  flex-direction: column;
  border-radius: 13px 0 0 0;
  background-color: var(--gray-light-color);
  padding: 1.25rem 1.25rem 1.25rem 1rem;

  &__title {
    text-transform: uppercase;
    color: var(--gray-color-3);
    margin-bottom: 0.5rem;
    font-size: var(--font-size-sm);
    @include m.media("mobile") {
      font-size: var(--font-size-s);
    }
  }

  &__text {
    font-size: var(--font-size-s);
  }
}

.detailedProjectAdditional {
  background-color: var(--gray-light-color);
  padding: 1.25rem;
  border-radius: 0.625rem;
  position: relative;

  &__title {
    text-transform: uppercase;
    color: var(--gray-color-3);
    margin-bottom: 1.25rem;
    font-size: var(--font-size-sm);
    font-weight: 400;
    @include m.media("last") {
      font-size: 0.75rem;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.875rem 3rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 0.25rem;
      background-color: var(--white-color);
      @include m.centerWithPositioning;
    }

    @include m.media("last") {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.25rem 0;
      &::before {
        display: none;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__heading {
    font-size: var(--font-size-s);
    font-weight: 700;
  }

  &__content {
    font-size: var(--font-size-s);;
    line-height: 1rem;
  }
}
