@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.notification__content {
  cursor: pointer;
  overflow: hidden;
  padding-top: 0.25rem;
  transition: v.$default-transition;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    transition: v.$default-transition;
  }
  &.overflow {
    &::after {
      background-image: linear-gradient(transparent 1rem, v.$white-color);
    }
  }
}