@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.radioTitle {
	font-size: 2rem;
	@include m.media("mobile") {
		font-size: 20px;
		width: 80%;
		margin-bottom: 2rem;
	}
	font-weight: bold;
	line-height: 1.2;
	margin-bottom: 1rem;
	width: 80%;
}
.chosen__option {
	font-size: 22px;
}
.text-gray-3 {
	font-size: 22px;
	@include m.media("mobile") {
		font-size: 15px;
		margin-bottom: 2rem;
	}
	color: #757575;
}
.radioContent {
	padding: 2rem;
	@include m.media("mobile") {
		padding: 10rem 2rem 0 2rem;
	}
}

.radioButtons__group {
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
	justify-content: space-around;
	flex-direction: column;
	padding-top: 1rem;
	font-size: 22px;
}