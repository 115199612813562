@use "../../../../assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;


.modal {
  &__container {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    @include m.media("last") {
      margin: 0.8rem;
    }
  }

  &__button {
    margin: 1rem;
  }

  &__dateContainer {
    display: flex;
    flex-direction: row;
  }
  &__loading {
    max-width: 2rem;
  }

  &__select {
    border: none;
    border-radius: 0.625rem;
    font-size: var(--font-size-m);
    padding: 0.5rem 1rem;
    scrollbar-width: none;
    -ms-overflow-style: none;

  }

  &__select::-webkit-scrollbar {
    width: 0;
  }

  &__select option[selected]  {
    background: var(--gray-color)
  }

  &__title {
    margin-bottom: 2rem;
    font-size: var(--font-size-l);
    @include m.media("last") {
      font-size: var(--font-size-m);
      margin-bottom: 1rem;
    }
  }

  &__text {
    font-size: var(--font-size-m);
    margin: 1rem 0;
  }

  &__date {
    font-size: var(--font-size-m);
    margin: 1rem 2rem 1rem 0;
    color: var(--gray-dark-color);
  }
}