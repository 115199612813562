@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.input-money {
  background-color: inherit;
  flex: 1;
  width: 100%;
  border: none;
  @include m.media("last", "min") {
    font-size: var(--font-size-l);
  }
  &:focus {
    outline: none;
  }
  &__container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    max-width: 100%;
    min-height: 3rem;
    background-color: var(--gray-light-color);
    padding: 0 1.25rem;
    border-radius: 0.625rem;
    @include m.media("mobile") {
      min-height: 2.5rem;
    }
  }
}