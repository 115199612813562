@use "src/assets/scss/abstracts/mixins" as m;

.profile-credential {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  &__heading {
    font-size: 1.5rem;
    @include m.media("last") {
      font-weight: 700;
      font-size: 0.875rem;
    }
  }
  &__description {
    @include m.media("last") {
      color: var(--gray-color-3);
      font-size: 0.625rem;
    }
  }
}