@use "../../../../assets/scss/abstracts/variables" as v;
@use "../../../../assets/scss/abstracts/mixins" as m;

.debt-class {
  height: 2rem;
  min-width: 2rem;
  padding-inline: 0.5rem;
  color: var(--gray-light-color-2);
  background-color: var(--gray-light-color);
  @include m.centerWithFlex;
  font-weight: 700;
  font-size: 0.875rem;
  cursor: pointer;
  border-radius: 0.25rem;

  &.active {
    color: var(--white-color);
    background-color: var(--primary-green-color);
  }
}