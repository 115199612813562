@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.identification-type-changer {
  padding: 1.875rem 1.5rem;
  width: 100%;
  background-color: var(--gray-light-color);
  color: var(--black-color);
  cursor: pointer;
  border-radius: 1.25rem;
  @include m.centerLineWithFlex;
  gap: 1rem;
  box-shadow: 0 3px 50px rgba(v.$black-color, 0.05);
  @include m.media("second") {
    padding: 1.25rem;
  }
  @include m.media("mobile") {
    padding: 0.75rem 0.5rem;
  }
  &.active {
    background-color: var(--primary-green-color-lighter);
    font-weight: 700;
  }
  &__point {
    flex-shrink: 0;
    position: relative;
    border: 2px solid var(--gray-color-2);
    border-radius: 50%;
    @include m.box(1.5rem);
    background-color: var(--white-color);
    &.active {
      border: none;
      &::after {
        content: '';
        position: absolute;
        @include m.centerWithPositioning;
        border-radius: 50%;
        background-color: var(--primary-green-color);
        @include m.box(0.95rem);
      }
    }
  }
}