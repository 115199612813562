@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.block-body {
  flex: 1;
  padding-block: 1rem;
  background-color: var(--white-color);
  border-radius: 0 0 v.$block-border-radius v.$block-border-radius;
  &--padding {
    padding-inline: 1rem;
  }
}