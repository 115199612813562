@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

$toggle-width-m: 3.125rem;
$toggle-height-m: calc(3.125rem / 2);

$toggle-width-s: 1.6rem;
$toggle-height-s: calc(1.6rem / 2);

@mixin toggle-styles($width, $height) {
  width: $width;
  height: $height;
  border-radius: 1.25rem;
  transition: all 0.3s;
  background-color: var(--gray-light-color-2);
  position: relative;
  cursor: pointer;

  &__button {
    background-color: var(--white-color);
    border-radius: 1.25rem;
    position: absolute;
    transition: all 0.3s;
    top: 50%;
    transform: translateY(-50%);
    left: 2px;
    box-shadow: 0 0.1em 0.3em rgba(v.$black-color, 0.3);
  }

  &.active {
    background-color: var(--primary-green-color);

    & > .toggle-m__button {
      left: calc(50% + 2px);
    }
    & > .toggle-s__button {
      left: calc(50% + 2px);
    }
  }

  &.disabled {
    cursor: initial;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba(v.$gray-color-2, 0.55);
      z-index: 1;
    }

    & .toggle__button {
      z-index: 2;
    }
  }
}

@mixin button-size($size) {
  width: $size;
  height: $size;
}

.toggle-s {
  @include toggle-styles($toggle-width-s, $toggle-height-s);

  &__button {
    @include button-size(0.625rem);
  }
}

.toggle-m {
  @include toggle-styles($toggle-width-m, $toggle-height-m);

  &__button {
    @include button-size(calc(1.5rem - 4px));
  }
}