@use "src/assets/scss/abstracts/mixins" as m;

.market-navigation {
  align-self: stretch;
  display: flex;
  align-items: stretch;
  gap: 3rem;
  @include m.hideOnBreakpoint("mobile", "max");
  &__item {
    @include m.centerWithFlex;
    align-self: stretch;
  }
  &__link {
    display: flex;
    align-items: center;
    align-self: stretch;
    color: var(--gray-color-3);

    @include m.underscore(calc(100% + 1.5rem), calc(100% + 1.25rem), 3px);
    &:hover {
      color: inherit;
    }
    &.active {
      font-weight: 700;
      color: var(--black-color);
    }
  }
}