@use "src/assets/scss/abstracts/mixins" as m;

.notifications-options {
  &-non-modal {
    @include m.hideOnBreakpoint("mobile");
  }
  &__block-header {
    padding: 1rem 1.25rem;
  }
  &__header {
    font-size: 1.5rem;
  }
  &__subheader {
    font-size: 0.875rem;
  }
  &__block-body {
    padding-block: 0;
  }
  &__element {
    display: flex;
    align-items: center;
    padding-block: 1.25rem;
    justify-content: space-between;
    &:not(:last-child) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: calc(100% + 2rem);
        height: 2px;
        left: -1rem;
        top: 100%;
        background-color: var(--gray-light-color);
      }
    }
  }
  &__start {
    display: flex;
    flex: 1;
    @include m.media("second") {
      flex-direction: column;
    }
  }
  &__text {
    font-size: 0.875rem;
    width: (calc(min(100%, 9rem)));
    @include m.media("second") {
      color: var(--gray-color-3);
    }
  }
  &__credential {
    flex: 1;
  }
  &__toggle {
    width: 1.625rem;
    height: 1rem;
    & .toggle__button {
      @include m.box(0.75rem);
    }
    &.active {
      & .toggle__button {
        left: calc(50% - 1px);
      }
    }
  }
}