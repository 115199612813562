.text-primary-green {
  color: var(--primary-green-color) !important;
}

.text-primary-green-light {
  color: var(--primary-green-color-lighter) !important;
}

.text-gray-3 {
  color: var(--gray-color-3) !important;
  padding-bottom: 1rem;
}

.text-black {
  color: var(--black-color) !important;
}

.text-lighter-gray-2 {
  color: var(--gray-light-color-2) !important;
}