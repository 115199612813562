@use "../../../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../../../assets/scss/abstracts/variables" as v;

$grid-columns: 8% 17% 30% 20% 20%;
$grid-columns-last: 20% 50% 20%;

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas:
          "icon title title date makeReport"
          "icon size watch dateText makeReport";
  grid-column-gap: 2%;
  grid-row-gap: 15%;

  @include m.media("last") {
    grid-template-columns: $grid-columns-last;
    grid-template-areas:
            "icon title size"
            "dateText dateText date"
            "makeReport makeReport makeReport"
  }
}