.explanationContent {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 80%;
	background: #F3F3F3 url('../../../assets/images/svg/default-background.svg') no-repeat 50% 20%;
	
	&__paragraphs {
		background-color: white;
		padding: 2rem;
	}
	
	&__firstParagraph {
		padding-bottom: 1rem;
	}
}

