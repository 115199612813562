@use "src/assets/scss/abstracts/variables" as v;

.notifications-type-select {
  &__image {
    transition: v.$default-transition;
    transform: rotate(180deg);
    &.active {
      transform: rotate(0deg);
    }
  }
  &__header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: inherit;
    color: var(--primary-green-color);
  }
  &__content {
    padding-block: 0;
    padding-inline: 1rem;
    transition: v.$default-transition;
    width: max-content;
    &.active {
      padding-block: 0.75rem 1rem;
      overflow: hidden;
      max-height: 10rem;
    }
    & .option-box__item {
      background-color: inherit;
      color: var(--black-color);
      padding: 0;
      transition: v.$default-transition;
      &:hover {
        color: var(--primary-green-color);
      }
      &.selected {
        background-color: inherit;
        color: var(--primary-green-color);
      }
    }
  }
}