@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.main-grid {
  display: grid;
  grid-template-columns: 1fr 30rem;
  grid-gap: 2.5rem 1rem;
  @include m.media("first") {
    grid-template-columns: 1fr 24rem;
  }
  @include m.media("second") {
    grid-template-columns: 1fr 18rem;
  }
  @include m.media("mobile") {
    grid-template-columns: 1fr;
  }
}