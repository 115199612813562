@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.detailedProjectMain {
  box-shadow: v.$default-box-shadow;
  border-radius: 1.25rem;
  padding: 1.5rem;
  background-color: var(--white-color);
  grid-column: 1 / 2;

  &__stats {
    display: grid;
    grid-template-columns: 8% repeat(2, 17%) repeat(4, 12%);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    grid-gap: 0 0.5rem;
    align-items: center;
    border-bottom: 3px solid rgba(146,153,162, 30%);
    &:last-child {
      border-bottom: none;
      padding-top: 1rem;
    }
    @include m.media("second") {
      grid-template-columns: 1fr ;
    }
  }
}

.detailedProjectStat {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
  @include m.media("second") {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    border-bottom: 1px solid rgba(146,153,162, 30%);
    margin-bottom: 0.8rem;
    padding-bottom: 0.8rem;
    &:last-child{
      border-bottom: none;
    }
    &:first-child{
      border-bottom: none;
    }
  }
  &__title {
    background-color: var(--gray-light-color);
    border-radius: 0.625rem;
    padding: 0.1rem 1rem;
    font-size: var(--font-size-s);
    font-weight: 700;
    color: var(--gray-color);
    @include m.media("second") {
      max-width: 4rem;
      justify-self: center;
    }
  }
  &__type {
    font-size: var(--font-size-sm);
    color: var(--gray-color-3);
  }
  &__value {
    font-size: var(--font-size-m);
    font-weight: 400;
    text-align: right;
    @include m.media("second") {
      text-align: end;
    }
  }
}

