@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.finalContent {
	position: relative;
	display: flex;
	justify-content: space-between;
	@include m.media("mobile") {
		flex-direction: column;
		height: 80%;
		justify-content: space-around;
	}
	align-items: flex-start;
	width: 100%;
	padding: 2rem;
	gap: 1rem;
}

.finalText,
.calendly__inlineWidget {
	flex: 1;
	@include m.media("mobile") {
		flex: 0;
	}
}

.finalText {
	width: 100%;
}

.finalTitle {
	font-size: 2rem;
	@include m.media("mobile") {
		font-size: 20px;
	}
	font-weight: bold;
	line-height: 1.2;
	color: #333333;
	margin-bottom: 1rem;
}

.text-gray-3 {
	font-size: 20px;
	@include m.media("mobile") {
		font-size: 15px;
	}
	color: #757575;
}

.calendlyButton__mobile {
	padding: 1rem 2rem;
}

.calendly__inlineWidget {
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
	min-width: 400px;
	height: 603px;
}

.onboardingModal__stepsCount {
	@include m.media("mobile") {
		display: none;
	}
	position: absolute;
	top: 2rem;
	right: 2rem;
}

.nextButton {
	@include m.media("mobile") {
		display: none;
	}
	border-color: #e6e6e6;
	cursor: pointer;
	align-self: flex-end;
	bottom: 0;
	right: 0;
	padding: 1rem 2rem;
	margin-left: 40px;
}
.previousButton {
	@include m.media("mobile") {
		display: none;
	}
	border-color: #e6e6e6;
	cursor: pointer;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 1rem 2rem;
	margin-left: 40px;
	margin-bottom: 2rem;
}