.secondary-button {
  display: flex;
  border-radius: 0.55rem;
  padding: 0.35em 1em;
  border: 3px solid var(--black-color);
  background-color: var(--gray-light-color);
  font-weight: 300;
  color: var(--black-color);
  cursor: pointer;
  &:hover, &:active {
    color: inherit;
  }
  &--orange {
    border-color: var(--primary-orange-color);
  }
  &--green {
    border-color: var(--primary-green-color);
  }
}