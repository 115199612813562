@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

$currentLast-breakpoint: 37.5em;

.profile {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  @include m.media("mobile", "min") {
    max-width: 55rem;
  }
  &__main {
    background-color: var(--white-color);
    padding: 1rem 1rem 1.25rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    box-shadow: v.$default-box-shadow;
  }
  &__info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include m.media($currentLast-breakpoint) {
      flex-direction: column;
      flex-flow: column-reverse;
    }
  }
  &__code {
    &-container {
      display: flex;
      align-items: center;
      gap: 1rem;
      @include m.media($currentLast-breakpoint) {
        align-self: flex-end;
      }
    }
  }
  &__contacts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.75rem;
    @include m.media($currentLast-breakpoint) {
      grid-template-columns: 1fr;
    }
  }
  &__contact {
    display: flex;
    flex-direction: column;
    @include m.media("last") {
      font-size: 0.75rem;
    }
    @include m.media($currentLast-breakpoint) {
      font-size: 1rem;
    }
  }
  &__phone {
    @include m.media("mobile") {
      place-items: center;
    }
    @include m.media($currentLast-breakpoint) {
      place-items: flex-start;
    }
  }
  &__passport {
    @include m.media("mobile") {
      place-items: flex-end;
    }
    @include m.media($currentLast-breakpoint) {
      place-items: flex-start;
    }
  }
  &__user {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  &__photo {
    @include m.box(5rem);
    border-radius: 50%;
    & > img {
      width: 100%;
    }
  }
  &__credential {
    color: var(--gray-color-3);
    font-size: 0.875rem;
  }
  &__name {
    width: min-content;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    & > * {
      width: 100%;
    }
  }
}