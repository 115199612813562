@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.radioTitle {
	font-size: 2rem;
	@include m.media("mobile") {
		font-size: 20px;
		width: 80%;
	}
	font-weight: bold;
	line-height: 1.2;
	margin-bottom: 1rem;
	width: 80%;
}
.text-gray-3 {
	font-size: 22px;
	@include m.media("mobile") {
		font-size: 15px;
	}
	color: #757575;
}
.radioContent {
	padding: 2rem;
	@include m.media("mobile") {
    padding: 10rem 2rem 0 2rem;
  }
}
.checkPurposes {
	display: flex;
	justify-content: space-between;
	@include m.media("mobile") {
		flex-wrap: wrap;
		justify-content: unset;
	}
	gap: 1rem;
	padding-top: 2rem;
}

.checkPurposes__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 11rem;
	width: 13rem;
	@include m.media("mobile") {
		width: 10rem;
	}
	border: 2px solid #e0e0e0;
	border-radius: 10px;
	cursor: pointer;
	transition: border-color 0.3s ease;
}

.checkPurposes__itemTitle {
	margin: 1rem 4rem;
	font-size: 16px;
	text-align: center;
}

.selected {
	border-color: #22a873;
	box-shadow: 0 0 10px rgba(34, 168, 115, 0.5);
}