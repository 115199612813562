@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.navigation-dropdown {
  @include m.hideOnBreakpoint("mobile", "min");
  @include m.media("last", "min") {
    padding-block: 1rem;
    padding-left: 1rem;
  }
  &__head {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
  }
  &__content {
    position: absolute;
    top: calc(100%);
    left: 0.5rem;
    z-index: 10;
    overflow: hidden;
    max-height: 0;
    width: max-content;
    transition: v.$default-transition;
    background-color: var(--white-color);
    border-radius: 0.625rem;
    box-shadow: 0 3px 3.125rem rgba(v.$black-color, 0.15);
    &.active {
      max-height: 50vh;
    }
  }
  &__list {
    padding-block: 1.25rem 1.5rem;
    list-style-type: none;
  }
  &__element {
    padding: 0.625rem 1rem;
    cursor: pointer;
  }
  &__link {
    color: var(--black-color);
    &:hover, &.active {
      color: var(--primary-green-color);
    }
  }
  &__active {
    font-weight: 700;
  }
  &__image {
    transition: v.$default-transition;
    transform: rotate(180deg);
    &.active {
      transform: rotate(360deg);
    }
  }
}