@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;


.main {
  background-color: var(--gray-light-color);
  padding-top: 9rem;
  @include m.media("mobile") {
    padding-top: 4.5rem;
  }
}