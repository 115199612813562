@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.transaction-partial-filter {
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  @include m.media("mobile", "max") {
    padding: 0.75rem;
  }
  @include m.media("last") {
    padding: 0.5rem;
  }
  @include m.media(26rem) {
    padding: 0;
  }
  &__navigation {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    @include m.media("mobile", "max") {
      position: absolute;
      inset: 0;
      justify-content: flex-end;
    }
  }
}