@use "src/assets/scss/abstracts/variables" as v;

#overlay {
  position: fixed;
  inset: 0;
  pointer-events: none;
  user-select: none;
  z-index: 50;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  transition: opacity 0.25s ease-in;
  
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}
