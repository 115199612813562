@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.secondary-project {
  display: grid;
  grid-template-columns: 1fr 30rem;
  grid-gap: 1rem;
  @include m.media("first") {
    grid-template-columns: 1fr 22.5rem;
  }
  @include m.media("second") {
    grid-template-columns: 1fr 18rem;
  }
  @include m.media("mobile") {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
  &-back {
    @include m.hideOnBreakpoint("mobile", "min");
    margin-bottom: 0.5rem;
    @include m.box(2rem);
    border-radius: 50%;
    @include m.centerWithFlex;
    background-color: var(--white-color);
    transform: rotate(90deg);
  }
  &-outlet {
    @include m.media("mobile") {
      grid-column: 1 / -1;
    }
  }
  &__header {
    .block-dropdown__heading {
      font-size: 1rem;
    }
    @include m.media("second") {
      padding-block: 0.5rem;
    }
  }
}