@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.block-dropdown {
  &.active {
    & .block-dropdown__footer-close {
      opacity: 1;
    }
  }
  &__body {
    padding-block: 0;
    transition: all 0.5s ease-out;
    overflow: hidden;
  }
  &__content {
    &-inner {
      padding-block: 1rem;
    }
  }
  &__footer:has(.block-dropdown__footer-close) {
    & {justify-content: flex-end;}
  }
  &__footer, &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__heading {
    @include m.media("mobile", "min") {
      font-size: 1.5rem;
    }
    &--only {
      flex: 1;
    }
  }
  &__toggle {
    & > button {
      color: var(--primary-green-color);
    }
    @include m.media("mobile") {
      font-size: 0.875rem;
    }
    &-arrow {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
  &__footer-close {
    cursor: pointer;
    @include m.box(2rem);
    background-color: var(--white-color);
    @include m.centerWithFlex;
    border-radius: 50%;
    opacity: 0;
    transform: rotate(180deg);
  }
}