@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.notifications-link {
  display: block;
  @include m.box(2.25rem);
  @include m.centerWithFlex;
  border-radius: 50%;
  padding: 0.25rem;
  background: var(--primary-green-color-lighter);
  &.active {
    background-color: var(--gray-color);
  }
}