@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

$grid-columns: minmax(12rem, 1fr) 4rem 4rem minmax(7rem, 1fr) minmax(7rem, 1fr) minmax(7rem, 1fr) 4rem 4rem 7rem 4rem 5rem ;
$grid-columns-first: minmax(12rem, 1fr) 4rem 4rem minmax(7rem, 1fr) minmax(7rem, 1fr) minmax(7rem, 1fr) 4rem 4rem 4rem 7rem 5rem ;
$grid-columns-second: minmax(9rem, 1fr) 3rem 4rem minmax(6rem, 1fr) minmax(6rem, 1fr) minmax(6rem, 1fr) 3rem repeat(2, minmax(4rem, 1fr)) 5rem 4rem ;
$grid-columns-mobile: repeat(2, minmax(max-content, 1fr)) repeat(4, 1fr);
$grid-columns-last: repeat(3, 1fr);

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: "primary category status investment actual-payment loan term simple yearly transactions stages";
  grid-column-gap: 1.25rem;
  @include m.media("first") {
    grid-template-columns: $grid-columns-first;
    grid-column-gap: 1rem;
  }
  @include m.media("second") {
    grid-template-columns: $grid-columns-second;
    grid-column-gap: 0.375rem;
  }
  @include m.media("mobile") {
    grid-template-columns: $grid-columns-mobile;
    grid-gap: 0.375rem 0.5rem;
    grid-template-areas:
          "primary primary primary category category category"
          "investment loan term simple yearly status"
          "transactions transactions transactions transactions actual-payment stages";
  }
  @include m.media("last") {
    grid-template-columns: $grid-columns-last;
    grid-template-areas:
          "primary primary category"
          "investment loan term"
          "simple yearly transactions"
          "stages actual-payment status";
    grid-column-gap: 0;
  }
}