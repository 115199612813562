.category-table {
  width: 35rem;
  height: 35rem;
  border: 0.7rem solid var(--gray-light-color);

  &__title {
    font-size: 0.8rem;
    text-align: center;
    font-weight: bold;
    color: var(--white-color);
    background: #508430;
    min-width: 3rem;
    padding: 0.5rem;
    border: 0.2rem solid var(--gray-light-color);
  }

  &__text, &__text__bold {
    font-size: 0.7rem;
    text-align: center;
    margin: 0;
  }

  &__text__bold {
    font-weight: bold;
  }

  &__cell,
  &__cell__excellent,
  &__cell__interesting,
  &__cell__classic,
  &__cell__perspective,
  &__cell__special,
  &__cell__illusory,
  &__cell__original,
  &__cell__difficult,
  &__cell__expert,
  &__cell__undefined,
  &__cell__empty {
    padding: 0.3rem;
    border: 0.2rem solid var(--gray-light-color);
  }

  &__cell__excellent {
    background: #CCE5BE;
  }

  &__cell__interesting {
    background: #AED497;
  }

  &__cell__classic {
    background: #9FCA81;
  }

  &__cell__perspective {
    background: #7AB950;
  }

  &__cell__special {
    background: #E4B677;

  }

  &__cell__illusory {
    background: #B1AFB0;

  }

  &__cell__original {
    background: #89C063;

  }

  &__cell__difficult {
    background: #8DC16B;

  }

  &__cell__expert {
    background: #8DC269;

  }

  &__cell__undefined {
    background: #E3A99B;

  }

  &__cell__empty {
    background: #949295;

  }

  &__download-button {
    background: #508430;
    margin: 1rem;
  }
}

