@use "src/assets/scss/abstracts/variables" as v;

.tertiary-button {
  display: block;
  min-width: 7rem;
  text-align: center;
  line-height: 2.5;
  padding-inline: 0.35rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 75%;
  transition: v.$default-transition;
  cursor: pointer;
  &--green {
    background-color: var(--primary-green-color-lighter);
    color: var(--black-color);
    &:hover {
      background-color: #a2fcbd;
      color: var(--black-color);
    }
    &.active {
      background-color: var(--gray-color-2);
      color: #fbfbfb;
    }
  }
  &--gray {
    background-color: var(--gray-light-color);
    color: var(--primary-green-color);
    &:hover {
      background-color: #e4e4e4;
      color: var(--primary-green-color);
    }
    &.active {
      color: var(--gray-light-color-2);
    }
  }
  &--white {
    background-color: var(--white-color);
    color: var(--gray-dark-color);
    &:hover {
      background-color: var(--gray-light-color);
      color: var(--gray-dark-color);
    }
    &.active {
      color: var(--gray-light-color-2);
    }
  }
  &--expanded {
    width: 100%;
  }
  &--large {
    padding: 0.5em 0.75em;
    font-size: 1.25rem;
  }
}