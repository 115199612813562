.form {
  &__group:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__input {
    border: none;
    outline: none;
    color: var(--gray-color);
    font-size: 115%;

    &::placeholder {
      color: var(--gray-dark-color);
      font-size: 80%;
      font-weight: 400;
    }

    &[type="text"], &[type="email"], &[type="password"] {
      width: 100%;
    }

    &[type="submit"] {
      display: block;
      background-color: var(--light-blue-color);
      border: none;
      border-radius: 2rem;
      color: var(--white-color);
      font-weight: 700;
      padding: 0.6rem 3rem;
      cursor: pointer;
      &:hover {
        background-color: var(--blue-dark-color);
      }
    }

    &[type="checkbox"] {
      opacity: 0;
      position: absolute;
      cursor: pointer;
    }

    &--left {
      flex: 1;
    }

    &-text {
      font-size: 85%;
      color: var(--gray-color);
    }

    &-container {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
      background-color: var(--white-color);
      padding: 0.3rem 0.75rem;
      border-radius: 0.5rem;
      border: 1px solid var(--gray-color);
      &--valid {
        border: 2px solid var(--primary-green-color);
      }
    }
  }
}

.check-container {
  cursor: pointer;
  position: relative;
  display: block;
  padding-left: 2rem;
  & .form__input:checked ~ .checkmark::after {
    position: absolute;
    inset: 0;
    content: "✓";
    text-align: center;
    background-color: var(--blue-color);
    color: var(--white-color);
    border-radius: 0.4rem;
  }
}

.checkmark {
  position: absolute;
  inset: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--white-color);
  border-radius: 0.4rem;
}
