@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.block-footer {
  background-color: var(--white-color);
  border-radius: 0 0 v.$block-border-radius v.$block-border-radius;
  padding: 0 0.25rem 0.25rem;
  &__inner {
    padding: 0.75rem;
    background-color: var(--gray-light-color);
    border-radius: 0 0 0.75rem 0.75rem;
  }
}