@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.agent-assignments {
  margin-bottom: 1rem;
  &__block-header {
    font-size: 1.25em;
  }
  &__header {
    @include m.hideOnBreakpoint("last");
    &-text {
      color: var(--gray-color-3);
    }
  }
}