@use "src/assets/scss/abstracts/mixins" as m;

.profile-credential-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-credential-title {
  color: var(--gray-color);
  @include m.media("last", "min") {
    font-size: 1.5rem;
  }
}