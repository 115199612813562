@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.autoinvest-settings {
  display: grid;
  grid-template-columns:
          minmax(6rem, 1fr)
          minmax(6rem, 1fr)
          minmax(6rem, 1fr)
          minmax(6rem, 1fr)
          minmax(10rem, 1fr)
          minmax(10rem, 1fr);
  grid-gap: 1rem;
  @include m.media("second") {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-gap: 1rem;
  }
  @include m.media("last") {
    grid-template-columns: 1fr;
  }
  &__placeholder {
    grid-column: 1 / -1;
    @include m.centerWithFlex;
    min-height: 2rem;
  }
  &__column {
    display: flex;
    margin-block:auto;
    @include m.media("last", "min") {
      flex-direction: column;
    }
    @include m.media("last") {
      justify-content: space-between;
      position: relative;
      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          width: calc(100% + 2rem);
          background-color: var(--gray-light-color);
          height: 2px;
          left: -1rem;
          top: 100%;
        }
      }
    }
  }
  &__heading {
    color: var(--gray-color-3);
    font-size: 0.75rem;
    max-width: 5.625rem;
    margin-bottom: 0.5rem;
  }
  &__text {
    font-size: calc(0.75rem + 1px);
    line-height: 1.5rem;
  }
  &__debt-classes {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
  }
  &__year-profit {
    &::after {
      display: none;
    }
  }
  &__submit {
    grid-column: 1 / -1;
  }
}