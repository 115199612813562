@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.transactions-project-select {
  &__image {
    transition: v.$default-transition;
    transform: rotate(180deg);
    &.active {
      transform: rotate(0deg);
    }
  }
  &__header {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background-color: inherit;
  }
  &__heading {
    color: var(--gray-color-3);
    font-size: 0.75rem;
    @include m.media("mobile") {
      font-size: 0.75rem;
    }
  }
  &__content {
    padding-block: 0;
    padding-inline: 1rem;
    transition: v.$default-transition;
    width: max-content;
    min-width: 16.375rem;
    &.active {
      padding-block: 0.75rem 1rem;
      overflow-x: hidden;
      max-height: 15rem;
    }
    & .multi-select__element {
      &:first-child {
        margin-bottom: 2.5rem;
      }
      background-color: inherit;
      color: var(--black-color);
      padding: 0;
      transition: v.$default-transition;
      &:hover {
        color: var(--primary-green-color);
      }
      &.selected {
        background-color: inherit;
        color: var(--primary-green-color);
      }
    }
  }
}