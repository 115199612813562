@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.enter-page {
  position: relative;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  background-color: v.$lighter-gray-color;
  background-image: url("../../../../src/assets/images/svg/login-background.svg");
  background-position: -20rem 50%;
  background-repeat: no-repeat;
  &__form {
    position: absolute;
    @include m.centerWithPositioning;
    top: 45%;
    background-color: var(--white-color);
    padding: 1.5rem;
    border-radius: 1.5rem;
    width: calc(min(90%, 26.25rem));
  }
  &__close {
    position: absolute;
    inset: 2rem 2rem auto auto;
    @include m.centerWithFlex;
  }
  &__image {
    &-container {
      margin-bottom: 2.25rem;
      max-width: 10.9375rem;
    }
  }
  &__disclaimer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    padding-inline: 1.5rem;
    @media(max-height: 48rem) {
      display:none;
    }
    text-align: center;
    width: calc(min(100%, 40rem));
    & > span {
      font-size: 85%;
    }
  }
}