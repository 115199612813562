@use "src/assets/scss/abstracts/mixins" as m;

.archived-projects-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &__title {
    @include m.media("mobile") {
      position: relative;
      z-index: 1;
    }
    @include m.media("last", "min") {
      font-size: 1.5rem;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    @include m.media("mobile") {
      position: absolute;
      inset: 0;
      justify-content: flex-end;
    }
  }
  &__search {
    @include m.media("mobile") {
      width: 100%;
    }
  }
}