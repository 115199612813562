.paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  &__container {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }
}

.my-page-link {
  padding: 0.375rem 0.75rem;
  border: none;
  background: none;
  color: var(--black-color);
  &:hover {
    color: inherit;
  }
  &--active {
    background-color: var(--primary-green-color-lighter);
    border-radius: 0.5rem;
    cursor: initial;
  }
}

.previous-page-link, .next-page-link {
  cursor: pointer;
  color: var(--primary-green-color);
  &:hover {
    color: inherit;
  }
  &--disabled {
    cursor: initial;
    pointer-events: none;
    color: var(--gray-dark-color);
  }
}