@use "src/assets/scss/abstracts/variables" as v;
@use "src/components/pages/detailed-project/styles/mixins" as dm;

.project-legal {
  @include dm.mountAnimation;
  &-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &__header {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  &__text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 2.25rem;
  }
  &__block {
    margin-bottom: 5rem;
    &-header, &-body {
      display: flex;
      align-items: center;
    }
    &-header {
      color: var(--gray-color-3);
      background-color: var(--gray-light-color);
    }
    &-text {
      padding: 0.75rem;
      &:first-child {
        min-width: 17.5rem;
        margin-right: 10rem;
      }
    }
  }
  &__table {
    font-size: 0.75rem;
    width: 100%;
    & > thead {
      background-color: #fbfbfb;
      text-transform: uppercase;
      color: var(--gray-color-3);
    }
    & > tbody {
      & tr {
        & > td {
          position: relative;
          &:nth-child(-n+3) {
            border-bottom: 2px solid var(--gray-light-color);
          }
          &:nth-child(4) {
            max-width: 12.5rem;
          }
          &:nth-child(5) {
            max-width: 13.75rem;
          }
        }
      }
    }
    & td {
      padding: 1rem 1rem 1rem 1.5rem;
    }
  }
}

.responsibilities-block {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.responsibility-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 95%;
  &__heading {
    font-weight: 700;
    font-size: 0.875rem;
  }
  &__text {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  &__court {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}