@use "src/assets/scss/abstracts/mixins" as m;

.archived-projects {
  &__buttons-container {
    position: relative;
  }
  &__download {
    position: absolute;
    top: 0;
    right: 4rem;
  }
}