@use "../grid" as gr;
@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;


.secondary-market-offer {
  @include gr.gridValues;
  padding: 1.5rem;
  position: relative;
  transition: v.$default-transition;
  background-color: var(--white-color);
  @include m.media("mobile", "min") {
    &:hover {
      background-color: var(--gray-light-color);
    }
  }
  &__header {
    @include m.hideOnBreakpoint("mobile", "min");
    font-size: 0.75rem;
    color: var(--gray-color-3);
    margin-bottom: 0.625rem;
    @include m.media("last", "min") {
      white-space: nowrap;
    }
  }
  @include m.media("mobile") {
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      width: calc(100% - 3rem);
      @include m.centerWithPositioning;
      background-color: var(--gray-light-color);
    }
  }
  @include m.media("last") {
    &::before {
      display: none;
    }
  }
  &__column {
    font-size: 0.875rem;
    @include m.centerLineWithFlex;
    @include m.media("second") {
      font-size: 0.75rem;
    }
    @include m.media("mobile") {
      padding-block: 0.5rem;
      font-size: 0.875rem;
      display: flex;
      flex-direction: column;
    }
    @include m.media("last") {
      align-items: flex-start;
    }
  }
  &__category, &__primary {
    @include m.media("mobile", "min") {
      font-size: 0.75rem;
    }
  }
  &__primary {
    grid-area: primary;
    @include m.media("mobile") {
      align-items: flex-start;
      justify-content: flex-end;
    }
  }
  &__category {
    grid-area: category;
    display: flex;
    justify-content: center;
  }
  &__sum {
    grid-area: sum;
  }
  &__forecast {
    grid-area: forecast;
  }
  &__term {
    grid-area: term;
  }
  &__simple {
    grid-area: simple;
    @include m.media("mobile") {
      align-items: flex-end;
    }
    @include m.media("last") {
      align-items: flex-start;
    }
  }
  &__yearly {
    grid-area: yearly;
  }
  &__collected {
    grid-area: collected;
    @include m.media("last") {
      gap: calc(min(25%, 7rem));
    }
  }
  &__date {
    grid-area: date;
    @include m.media("mobile") {
      align-items: flex-start;
    }
  }
  &__minimum {
    grid-area: minimum;
    @include m.media("last") {
      gap: calc(min(20%, 5rem));
    }
  }
  &__actions {
    grid-area: actions;
  }
  &__term, &__date, &__category {
    @include m.media("last") {
      align-items: flex-end;
    }
  }
  &__primary, &__category, &__sum, &__forecast, &__term, &__simple, &__yearly, &__date {
    @include m.media("last") {
      border-bottom: 2px solid var(--gray-light-color);
    }
  }
  &__money {
    @include m.media("mobile") {
      text-align: center;
    }
  }
  &__collected, &__minimum {
    @include m.media("last") {
      flex-direction: row;
      padding-block: 0;
    }
  }
  &__title {
    position: relative;
  }
  &__name {
    @include m.media("mobile") {
      @include m.lineClamp(2);
    }
  }
  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0.25rem;
      background-color: var(--gray-light-color);
      z-index: 2;
    }
  }
  &__link {
    font-size: 0.75rem;
    padding: 0.8em;
    @include m.media("second") {
      padding: 0.9em;
      font-size: 0.625rem;
    }
    @include m.media("mobile") {
      font-size: 0.9rem;
    }
  }
}