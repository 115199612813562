@use "src/assets/scss/abstracts/mixins" as m;
@use "src/components/pages/detailed-project/styles/mixins" as dm;
@use "src/assets/scss/abstracts/variables" as v;
.referral {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @include dm.mountAnimation;
}

.referral-program {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__link-container {
    display: flex;
    justify-content: space-between;
    background-color: v.$lighter-gray-color;
    padding: 16px 14px 16px 22px;
    border-radius: 10px;
    align-items: center;
  }
  &__heading {
    font-size: 14px;
    color: #9299a2;
    margin-bottom: 5px;
  }
  &__code {
    display: flex;
    gap: 1.875rem;
    align-items: center;
    margin-bottom: 1.25rem;

  }
  &__info {
    font-size: 0.875rem;
    @include m.media("last") {
      max-width: 20.625rem;
    }
  }
  &__button {
    font-size: 1rem;
  }
}

.secondary-heading {
  font-weight: 400;
}
.referral-users {
    // Стили для таблицы
  &__table {
    display: flex;
    flex-direction: column;

    .referral-header-date {
      display: flex;
      justify-content: space-between;
      background-color: #FBFBFB;
      color: var(--gray-color-3);
      font-size: 0.75rem;
      text-align: left;
      box-sizing: border-box;
      padding: 1rem;
      border-bottom: 1px solid #F6F7F8;

      span {
        flex: 1;
        text-align: left;
      }
    }

    .referral__item {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid #F6F7F8;

      span {
        flex: 1;
        text-align: left;
        font-size: 0.875rem;
        color: #000;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}


