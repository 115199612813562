@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.investment-confirmation {
  position: fixed;
  inset: 0;
  background-color: rgba(v.$white-color, 0.75);
  z-index: 15;
  overflow-y: scroll;
  &__close {
    position: fixed;
    inset: 2rem 2rem auto auto;
  }
  &__content {
    margin-block: 3rem;
    height: 1px;
    min-height: calc(100vh - 6rem);
    width: calc(min(90vw, 55rem));
    margin-inline: auto;
  }
  &__block {
    background-color: var(--gray-light-color);
    border-radius: 1.5rem;
    box-shadow: 0 3px 3.125rem rgba(0, 0, 0, 0.15);
    @include m.box(100%);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__header {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  &__text {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__document {
    background-color: var(--white-color);
    padding: 1.5rem;
    border-radius: 1.5rem;
    flex: 1;
  }
  &__info {
    margin-top: 0.5rem;
    color: var(--gray-color-3);
    font-size: 0.75rem;
    line-height: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}