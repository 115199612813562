@use "../../../../../../assets/scss/abstracts/variables" as v;
@use "../../../../../../assets/scss/abstracts/components/header" as hv;
@use "../../../../../../assets/scss/abstracts/mixins" as m;
@use "grid" as gr;

.documentsToProjectItemTitle {
  &__arrowButton {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: -3rem;
    top: 0;
    &__image {
      height: 1.5rem;
      width: 1.5rem;
      @include m.media("last") {
        height: 1rem;
        width: 1rem;
      }
      &__download {
        margin-left: 0.5rem;
        width: 1.5rem;
        @include m.media("mobile") {
          width: 1rem;
        }
      }
    }
    @include m.media("last") {
      right: -1.5rem;
    }
  }

  &__list {
    @include m.centerColumnWithFlex;
    margin-bottom: 3.75rem;
    width: 100%;
    padding-left: 15%;
    @include m.media("second") {
      padding-left: 0;
    }
    &:last-child {
      margin-bottom: 1rem;
    }
  }

  &__item {
    width: 100%;
    text-align: left;
    position: relative;
    @include m.media("last") {
      font-size: var(--font-size-s);
    }
  }
}

$grid-areas: icon, title, size, watch, date, dateText, makeReport;

.documentsToProjectBody {
  @include m.centerColumnWithFlex;
  align-items: normal;
  margin-bottom: 2rem;
  width: 65%;


  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }
  @include m.media("first") {
    width: 70%;
  }
  @include m.media("second") {
    width: 100%;
  }

  &__list {
    margin-bottom: 2rem;
    padding-left: 1rem;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }

    @include m.media("last") {
      padding-left: 0.75rem;
    }
  }

  &__invest {
    margin-bottom: 2rem;
    width: 100%;
    border-left: 4px solid var(--primary-green-color);
  }

  &__investContainer {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    @include gr.gridValues;
    color: var(--black-color);

    &:hover {
      .documentsToProjectBody__watch {
        display: block;
        @include m.media("last") {
          display: none;
        }
      }
    }
  }

  &__title {
    font-size: var(--font-size-m);
    color: var(--black-color);
    @include m.media("last") {
      font-size: var(--font-size-s);
    }
  }

  &__watch {
    display: none;
    cursor: pointer;
    @include m.media("last") {
      display: none;
    }
  }

  &__date {
    font-size: var(--font-size-s);
    color: var(--black-color);
    @include m.media("last") {
      font-size: var(--font-size-xs);
    }
  }

  &__dateText, &__size, &__watch {
    font-size: var(--font-size-s);
    color: var(--gray-dark-color);
    @include m.media("last") {
      font-size: var(--font-size-xs);
    }
  }

  &__makeReport {
    align-self: center;
    position: relative;
    padding-bottom: 1rem;
  }

  &__downloadAgentReport {
    margin: 1rem;
  }

  &__buttonSave {
    font-size: var(--font-size-s);
    border: none;
    width: 100%;
    color: var(--primary-green-color);
    border-radius: 12px;
    background: var(--white-color);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    text-align: center;
    cursor: pointer;
    padding: 0.3rem;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @include m.media("last") {
      font-size: var(--font-size-xs);
      padding: 0.3rem;
    }
  }

  &__icon {
    align-self: center;
    @include m.media("mobile") {
      width: 2rem;
    }
  }

  &__orderReport {
    border-radius: 12px;
    cursor: pointer;
    padding: 0.5rem;
    text-align: center;
    color: var(--white-color);
    background: var(--primary-green-color);
    border: none;
    font-size: var(--font-size-s);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    @include m.media("last") {
      font-size: var(--font-size-xs);
      padding: 0.3rem;
    }
  }

  &__download {
    @include m.centerColumnWithFlex;
    border-radius: 12px;
    min-width: 6rem;
    min-height: 3rem;
    background: var(--white-color);
    position: absolute;
    top: 1rem;
    left: 6rem;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    @include m.media("last") {
      top: 1rem;
      left: 70%;
    }

  }

  &__buttonDownload {
    cursor: pointer;
    padding: 0.3rem;
    text-align: center;
    color: var(--primary-green-color);
    border-radius: 12px;
    background: var(--white-color);
    border: none;
    font-size: var(--font-size-s);
  }

  &__incomeCertificate {
    cursor: pointer;
    padding: 0.5rem;
    text-align: center;
    color: var(--primary-green-color);
    border-radius: 12px;
    background: var(--white-color);
    font-size: var(--font-size-s);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border: none;
    width: 100%;
    @include m.media("last") {
      font-size: var(--font-size-xs);
      padding: 0.3rem;
    }
  }
}