@use "assets/scss/abstracts/mixins" as m;

.projects-header-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &__status-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 5rem;
    width: 70%;
    z-index: 2;
    @include m.media("second") {
      width: 80%;
    }
    @include m.media("mobile") {
      flex-direction: column;
      width: 50%;
      margin-left: 0.5rem;
    }
    @include m.media(32em) {
      width: 80%;
      margin-left: 0;
    }
  }
  &__status {
    height: 2rem;
    min-width: 2rem;
    padding-inline: 1rem;
    color: var(--gray-light-color-2);
    background-color: var(--white-color);
    @include m.centerWithFlex;
    font-size: 0.875rem;
    cursor: pointer;
    border-radius: 0.25rem;
    &.active {
      color: var(--white-color);
      background-color: var(--primary-green-color);
    }
    @include m.media("mobile") {
      margin-bottom: 0.5rem;
    }

  }
}