@use "../../../../../../assets/scss/abstracts/variables" as v;
@use "../../../../../../assets/scss/abstracts/components/header" as hv;
@use "../../../../../../assets/scss/abstracts/mixins" as m;
@use "../DocumentsElements/grid" as gr;

.documents {
  @include m.centerColumnWithFlex;
  width: 65%;
  margin: 0 auto;

  @include m.media("first") {
    width: 70%;
  }
  @include m.media("second") {
    width: 80%;
  }
  @include m.media("mobile") {
    width: 90%;
  }
  &__container {
    margin-top: 2rem;
    width: 100%;
  }
}
