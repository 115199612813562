@use "src/assets/scss/abstracts/variables" as v;

.withdraw-faq {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    @media screen and (min-width: map-get(v.$container-breakpoints, "mobile")) {
      max-width: 80%;
    }
  }
  &__element {
    font-size: 0.875rem;
  }
  &__question {
    cursor: pointer;
    color: var(--primary-green-color);
  }
  &__content {
    padding-block: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @media screen and (min-width: map-get(v.$container-breakpoints, "second")) {
      padding-left: 1.5rem;
    }
  }
  &__answer {
    overflow: hidden;
    max-height: 0;
    transition: v.$default-transition;
    &.active {
      max-height: 100vh;
    }
  }
}