@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.projects-filter {
  padding-block: 0;
  padding-inline: 1.5rem;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem 3rem;
  @include m.media("mobile") {
    grid-template-columns: 1fr 1fr;
  }
  &__values {
    position: relative;
    @include m.media("mobile", "min") {
      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          width: 2px;
          height: 120%;
          background-color: var(--gray-light-color);
          left: calc(100% + 1.5rem);
          @include m.centerColumnWithPositioning;
        }
      }
    }
  }
  &__debt {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    grid-column: span 2;
    &-heading {
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }
  }
  &__submit {
    grid-column: 1 / -1;
  }
  &__classes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &__slider {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &.active {
    max-height: 50vh;
    padding-block: 1.125rem 2.25rem;
  }
}