@use "src/assets/scss/abstracts/mixins" as m;

$grid-columns: minmax(12rem, 1fr) 4rem minmax(7rem, 1fr) minmax(8rem, 1fr) 4rem 1fr 1fr 7rem 1fr minmax(8rem, 1fr) minmax(8rem, 1fr);
$grid-columns-first: minmax(9rem, 1fr) 4rem minmax(6rem, 1fr) minmax(6rem, 1fr) 3.5rem 1fr 1fr 7rem 1fr minmax(8rem, 1fr) minmax(8rem, 1fr);
$grid-columns-second:
        minmax(5rem, 1fr)
        3.5rem
        minmax(5rem, 1fr)
        minmax(5rem, 1fr)
        2.75rem
        4rem
        4rem
        5rem
        minmax(4rem, 1fr)
        minmax(4.5rem, 1fr)
        minmax(5rem, 1fr);
$grid-columns-mobile: auto 1fr minmax(7rem, 1fr) minmax(7rem, 1fr) minmax(4rem, auto) 1fr;
$grid-columns-last: 1fr 1fr 5rem;

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: "primary category sum forecast term simple yearly collected date minimum actions";
  grid-column-gap: 1.25rem;
  @include m.media("first") {
    grid-template-columns: $grid-columns-first;
    grid-column-gap: 1rem;
  }
  @include m.media("second") {
    grid-template-columns: $grid-columns-second;
    grid-column-gap: 0.75rem;
  }
  @include m.media("mobile") {
    grid-template-columns: $grid-columns-mobile;
    grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
    grid-template-areas:
            "primary category sum forecast term simple"
            "date yearly minimum collected actions actions";
    grid-gap: 1rem;
  }
  @include m.media("last") {
    grid-template-columns: $grid-columns-last;
    grid-template-rows: none;
    grid-template-areas:
            "primary primary category"
            "sum forecast term"
            "simple yearly date"
            "collected collected collected"
            "minimum minimum minimum"
            "actions actions actions";
    grid-gap: 0.75rem 0;
  }
}