@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.invest-my-money {
  &__icon {
    @include m.box(2.5rem);
    background-color: var(--primary-green-color-lighter);
    border-radius: 50%;
    @include m.centerWithFlex;
    &--gray {
      background-color: var(--gray-color-2);
    }
  }
  &__block-header {
    min-height: 5.625rem;
    padding-block: 1rem;
    @include m.media("second") {
      min-height: 5rem;
    }
  }
  &__rest {
    grid-area: rest;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-inner {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  &__heading {
    font-size: 1.25rem;
    &--main {
      font-size: 1.5rem;
      @include m.media("second") {
        font-size: 1.25rem;
      }
      @include m.media("last") {
        font-size: 1rem;
      }
    }
  }
}


.finished-portfolio {
  &__header {
    justify-content: flex-start;
    gap: 1rem;
    @include m.media("last") {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__icon {
    @include m.hideOnBreakpoint("last");
  }
}