@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.custom-checkbox {
  position: relative;
  z-index: 1;
  @include m.centerLineWithFlex;
  min-height: 1.5rem;
  padding-left: 2.5rem;
  print-color-adjust: exact;
  &__input {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
  }
  &__label {
    position: relative;
    margin-bottom: 0;
    font-size: 0.875rem;
    line-height: 1.125rem;
    @include m.media("second") {
      font-size: 0.75rem;
    }
    &::after, &::before {
      content: "";
      position: absolute;
      cursor: pointer;
      left: -2.5rem;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      @include m.box(1.5rem);
      @include m.media("mobile") {
        left: -2rem;
        width: 1rem;
        height: 1rem;
      }
    }
    &::before {
      border: 2px solid var(--gray-light-color-2);
      background-color: var(--white-color);
      pointer-events: none;
      transition: all 0.15s ease-in-out;
      border-radius: 0.25rem;
    }
    &::after {
      background: 50%/50% 50% no-repeat;
    }
  }
  &__input:checked ~ &__label::before {
    color: var(--white-color);
    border-color: var(--primary-green-color);
    background-color: var(--primary-green-color);
  }
  &__input:checked ~ &__label::after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");;
  }
}