$gray-color: #b9b9b9;
$gray-color-2: #eeeeee;
$gray-color-3: #9299a2;
$darker-gray-color: darken($gray-color, 25%);
$lighter-gray-color: #f6f7f8;
$lighter-gray-color-2: #b7c0c8;
$blue-color: rgb(122, 148, 255);
$darker-blue-color: darken($blue-color, 25%);
$black-color: rgb(0, 0, 0);
$white-color: rgb(255, 255, 255);
$primary-green-color: #22a873;
$primary-green-color-lighter: #bfffd2;
$primary-green-color-darker: #198a5d;
$green-color-gray: #E1EEE4;
$red-color: rgb(255, 0, 0);
$primary-blue-color: #0098ff;
$primary-blue-color-darker: #0079ca;
$pink-color: #fee8e8;

$font-size-l: 20px;
$font-size-m: 16px;
$font-size-sm: 14px;
$font-size-s: 12px;
$font-size-xs: 10px;

:root {
  --white-color: #{$white-color};
  --black-color: #{$black-color};
  --red-color: #{$red-color};
  --pink-color: #{$pink-color};
  --primary-color: #2b2b2b;
  --light-blue-color: #468aff;
  --blue-color: #{$blue-color};
  --blue-dark-color: #{$darker-blue-color};
  --gray-color: #{$gray-color};
  --gray-color-2: #{$gray-color-2};
  --gray-color-3: #{$gray-color-3};
  --gray-dark-color: #{$darker-gray-color};
  --gray-light-color: #{$lighter-gray-color};
  --gray-light-color-2: #{$lighter-gray-color-2};
  --primary-green-color: #{$primary-green-color};
  --primary-green-color-lighter: #{$primary-green-color-lighter};
  --primary-green-color-darker: #{$primary-green-color-darker};
  --green-color-gray: #{$green-color-gray};
  --primary-yellow-color: #FDE893;
  --primary-orange-color: #fdc661;
  --primary-orange-color-2: #ffba3b;
  --primary-orange-color-3: #EA8024;
  --primary-blue-color: #{$primary-blue-color};
  --primary-blue-color-darker: #{$primary-blue-color-darker};

  --font-size-l: #{$font-size-l};
  --font-size-m: #{$font-size-m};
  --font-size-sm: #{$font-size-sm};
  --font-size-s: #{$font-size-s};
  --font-size-xs: #{$font-size-xs};
}

$container-max-width: 87rem;

$container-breakpoints: ("first": 93.75em, "second": 78.75em, "mobile": 62em, "last": 48em);

$block-border-radius: 0.75rem;
$block-padding: 0.65rem;

$default-box-shadow: 0 3px 3.125rem rgba($black-color, 0.05);

$default-transition: all 0.3s;