@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG'),
  url('../../../assets/fonts/GraphikLCG-Bold/GraphikLCG-Bold.ttf') format('truetype'),
  url('../../../assets/fonts/GraphikLCG-Bold/GraphikLCG-Bold.woff') format('woff'),
  url('../../../assets/fonts/GraphikLCG-Bold/GraphikLCG-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG'),
  url('../../../assets/fonts/GraphikLCG-Light/GraphikLCG-Light.ttf') format('truetype'),
  url('../../../assets/fonts/GraphikLCG-Light/GraphikLCG-Light.woff') format('woff'),
  url('../../../assets/fonts/GraphikLCG-Light/GraphikLCG-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG'),
  url('../../../assets/fonts/GraphikLCG-Medium/GraphikLCG-Medium.ttf') format('truetype'),
  url('../../../assets/fonts/GraphikLCG-Medium/GraphikLCG-Medium.woff') format('woff'),
  url('../../../assets/fonts/GraphikLCG-Medium/GraphikLCG-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG'),
  url('../../../assets/fonts/GraphikLCG-Regular/GraphikLCG-Regular.ttf') format('truetype'),
  url('../../../assets/fonts/GraphikLCG-Regular/GraphikLCG-Regular.woff') format('woff'),
  url('../../../assets/fonts/GraphikLCG-Regular/GraphikLCG-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LCG';
  src: local('Graphik LCG'),
  url('../../../assets/fonts/GraphikLCG-Semibold/GraphikLCG-Semibold.ttf') format('truetype'),
  url('../../../assets/fonts/GraphikLCG-Semibold/GraphikLCG-Semibold.woff') format('woff'),
  url('../../../assets/fonts/GraphikLCG-Semibold/GraphikLCG-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: 'Graphik LCG', sans-serif;
}

html, body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.no-scroll {
  overflow: hidden;
}