@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.withdraw-section {
  padding-bottom: 8.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.withdraw {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &-heading {
    font-size: 1.5rem;
  }
  &-main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.withdraw-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &__container {
    display: grid;
    grid-template-columns: minmax(27.75rem, 1fr) 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
    @media screen and (max-width: map-get(v.$container-breakpoints, "second")) {
      grid-template-columns: 1fr;
      & > * {
        grid-column: 1 / -1;
      }
    }
  }
  &__heading {
    grid-column: 1 / -1;
  }
  &__explanation {
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--gray-color-3);
    max-width: 20rem;
    @include m.media("first") {
      max-width: initial;
    }
    &-container {
      display: flex;
      @media screen and (min-width: map-get(v.$container-breakpoints, "second")) {
        justify-content: flex-end;
      }
    }
  }
  &__input {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &__min {
    color: var(--gray-color-3);
    font-size: 0.875rem;
  }
  &__submit {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    & > .primary-button {
      font-size: 1.25rem;
    }
  }
  &__check {
    margin-bottom: 1rem;
  }
}

.withdraw-modal {
  @include m.centerWithFlex;
  min-height: 30vh;
  text-align: center;
  font-size: 1.25rem;
}