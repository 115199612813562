$directions: ("r": "right", "l": "left", "t": "top", "b": "bottom", "il": "inline", "bl": "block");

@each $dir, $direction in $directions {
  .m-#{$dir}-auto {
    margin-#{$direction}: auto !important;
  }
  @for $i from 0 through 5 {
    .m-#{$dir}-#{$i} {
      margin-#{$direction}: #{$i}rem !important;
    }
    .p-#{$dir}-#{$i} {
      padding-#{$direction}: #{$i}rem !important;
    }
  }
}

.f-1 {
  flex: 1 !important;
}

$displays: ("bl": "block", "fl": "flex", "none": "none");

@each $dis, $display in $displays {
  .d-#{$dis} {
    display: #{$display} !important;
  }
}

@for $i from 1 through 5 {
  .g-#{$i} {
    gap: #{$i}rem !important;
  }
}

$sizes: 25, 50, 75, 100;

@each $size in $sizes {
  .w-#{$size} {
    width: #{$size} + "%" !important;
  }
  .h-#{$size} {
    height: #{$size} + "%" !important;
  }
}

@for $i from 1 through 7 {
  $weight: $i * 100;
  .f-w-#{$weight} {
    font-weight: #{$weight} !important;
  }
}

.flex-column {
  flex-direction: column !important;
}

$position-values: ("rel": "relative", "abs": "absolute", "fix": "fixed", "sticky": "sticky");

@each $key, $value in $position-values {
  .pos-#{$key} {
    position: #{$value} !important;
  }
}