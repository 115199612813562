@use "variables" as v;

@mixin centerWithFlex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin centerLineWithFlex() {
  display: flex;
  align-items: center;
}

@mixin centerColumnWithFlex() {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin centerWithPositioning() {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@mixin centerLineWithPositioning() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin centerColumnWithPositioning() {
  top: 50%;
  transform: translateY(-50%);
}

@mixin box($size) {
  width: $size;
  height: $size;
}

$widths: (min: min-width, max: max-width);

@mixin hideOnBreakpoint($breakpoint, $width: "max") {
  @if(map-has-key($widths, $width) == false) {
    @error "Please, provide valid width parameter";
  }
  @if(type-of($breakpoint) == "number") {
    @media screen and (#{$width + "-width"}: $breakpoint) {
      display: none !important;
    }
  }
  @else if(map-has-key(v.$container-breakpoints, $breakpoint)) {
    $local-breakpoint: map-get(v.$container-breakpoints, $breakpoint);
    @if($width == "max") {
      $local-breakpoint: calc($local-breakpoint - 1px);
    }
    @media screen and (#{$width + "-width"}:$local-breakpoint) {
      display: none !important;
    }
  }  @else {
    @error "Please, provide valid breakpoint";
  }
}

@mixin media($breakpoint, $width: "max") {
  @if(map-has-key($widths, $width) == false) {
    @error "Please, provide valid width parameter";
  }
  @if(map-has-key(v.$container-breakpoints, $breakpoint)) {
    @media screen and (#{$width + "-width"}: map-get(v.$container-breakpoints, $breakpoint)) {
      @content
    }
  } @else if(type-of($breakpoint) == "number") {
    @media screen and (#{$width + "-width"}: $breakpoint) {
      @content
    }
  } @else {
    @error "Please, provide valid breakpoint";
  }
}

@mixin underscore($width: 100%, $top: 100%, $height: 2px) {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: #{$top};
    width: 0;
    @include centerLineWithPositioning;
    height: #{$height};
    background-color: var(--primary-green-color);
    transition: all 0.3s;
  }
  &.active, &:hover {
    &::after {
      width: #{$width};
    }
  }
}

@mixin lineClamp($number) {
  @if(type-of($number) != "number") {
    @error "Provide number of lines as number type";
  }
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
}