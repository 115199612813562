@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.common-icon {
  @include m.box(2.5rem);
  @include m.centerWithFlex;
  transition: v.$default-transition;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--white-color);
  position: relative;
  z-index: 5;
  &:hover, &.active {
    background-color: var(--primary-green-color);
    & > svg path, & > svg g, & > svg circle, & > svg line {
      fill: var(--white-color);
      stroke: var(--white-color);
    }
    & > svg:first-child circle {
      fill: var(--primary-green-color);
    }
    & > svg rect {
      fill: var(--white-color);
    }
  }
  &--download {
    &:hover{
      & > svg path:first-child {
        fill: none !important;
      }
    }
  }
  &--favorites {
    &.active {
      background-color: var(--white-color);
    }
  }
}