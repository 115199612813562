@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;
@use "src/components/pages/archived/grid" as gr;

.archived-projects {
  &-header {
    @include gr.gridValues;
    &__item {
      display: flex;
      align-items: flex-end;
    }
    &__category, &__investment, &__loan, &__term, &__simple, &__yearly {
      @include m.media("second") {
        justify-content: center;
        text-align: center;
      }
    }
  }
  &-block-body {
    padding-block: 0 0.25rem;
  }
}