@use "src/assets/scss/abstracts/mixins" as m;
.identification-type-select {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 1rem;
  margin-bottom: 1.5rem;
  &--PC {
    @include m.media("mobile"){
      display:none;
    }
  }
  &--mobile {
    @include m.media("mobile",'min'){
      display:none;
    }
  }
}

.identification-section {
  width: calc(min(100%, 58.5rem));
}