@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.common-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.625rem;
  border: 2px solid v.$gray-color-2;
  padding: 0.125rem;
  &__left {
    flex: 1;
    position: relative;
  }
  &__right {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  &__input {
    width: 100%;
    min-height: 3.5rem;
    padding: 1.25rem 1rem 0 1rem;
    border: none;
    background-color: inherit;
    &:focus {
      outline: none;
    }
  }
  &__label {
    position: absolute;
    pointer-events: none;
    left: 1rem;
    right: 1rem;
    @include m.centerColumnWithPositioning;
    white-space: nowrap;
    overflow: hidden;
    line-height: 2.5rem;
    transition: all 0.3s;
    top: 25%;
    font-size: 0.875rem;
  }
  &__required {
    color: v.$red-color;
  }
  &__disclaimer {
    @include m.hideOnBreakpoint('mobile');
    font-size: 0.875rem;
    color: #9299a2;
    &--required {
      font-size: 1.5rem;
      padding-top: 0.7rem;
    }
    &--mobile {
      @include m.hideOnBreakpoint('mobile')
    }
  }
}