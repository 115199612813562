@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.sms-confirmation {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  &__button {
    @include m.box(2rem);
    @include m.centerWithFlex;
    border-radius: 50%;
    background-color: var(--primary-green-color-lighter);
    border: none;
    margin-bottom: 1.5rem;
  }
  &__header {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
  }
}