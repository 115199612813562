@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.secondary-project-main {
  box-shadow: v.$default-box-shadow;
  border-radius: 1.25rem;
  padding: 1.5rem;
  background-color: var(--white-color);
  &__code {
    font-size: 0.875rem;
    color: var(--gray-color-3);
  }
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  &__status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &__category {
    background-color: var(--primary-green-color-lighter);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 4.5rem;
    padding: 0.05rem 0.4rem;
    border-radius: 1.25rem;
  }
  &__class {
    color: var(--primary-green-color);
    font-weight: 500;
  }
  &__project {
    margin-bottom: 2.125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include m.media("mobile") {
      font-size: 0.75rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;
    }
  }
  &__investment-money {
    background-color: v.$lighter-gray-color;
    padding: 1.25rem;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    gap: 1rem;

  }
  &__name {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.875rem;
  }
  &__info {
    font-size: 0.75rem;
    max-width: 19.125rem;
  }
  &__stats {
    display: grid;
    grid-template-columns: 1fr 1fr 4rem 9rem 9rem;
    margin-bottom: 3rem;
    grid-gap: 0 2rem;
    @include m.media("second") {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem 3rem;
    }
    @include m.media(39em) {
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
    }
  }
}

.secondary-project-stat {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &__type {
    font-size: 0.875rem;
    color: var(--gray-color-3);
    &--sum {
      @include m.media("mobile") {
        font-size: 0.75rem;
      }
    }
  }
  &__value {
    font-size: 1.125rem;
    font-weight: 500;
    &--sum {
      @include m.media("mobile") {
        font-size: 0.875rem;
      }
    }
    &--centered {
      @include m.media("second", "min") {
        text-align: center;
      }
    }
  }
}

.secondary-project-additional {
  background-color: var(--gray-light-color);
  padding: 1.25rem;
  border-radius: 0.625rem;
  position: relative;
  &__help {
    position: absolute;
    inset: 0.5rem 0.5rem auto auto;
  }
  &__title {
    text-transform: uppercase;
    color: var(--gray-color-3);
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
    @include m.media("last") {
      font-size: 0.75rem;
    }
  }
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.875rem 3rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 0.25rem;
      background-color: var(--white-color);
      @include m.centerWithPositioning;
    }
    @include m.media("last") {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.25rem 0;
      &::before {
        display: none;
      }
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__heading {
    font-size: 0.75rem;
    font-weight: 700;
  }
  &__content {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}