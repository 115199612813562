@use "src/assets/scss/abstracts/mixins" as m;

.grid-row-header {
  @include m.hideOnBreakpoint("mobile", "min");
  font-size: 0.75rem;
  color: var(--gray-color-3);
  margin-bottom: 0.625rem;
  @include m.media("last", "min") {
    white-space: nowrap;
  }
}