@use "src/assets/scss/abstracts/mixins" as m;

.survey__checkbox-group {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @include m.media("last") {
    padding: 0.5rem;
  }

  &__question {
    margin-bottom: 1rem;
    @include m.media("last") {
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }
  }
  &__answer {
    margin-bottom: 1rem;
  }
}
