@use "src/assets/scss/abstracts/mixins" as m;

.project-progress {
  background-color: var(--gray-color-2);
  border-radius: 0.75rem;
  @include m.centerWithFlex;
  position: relative;
  min-width: 7rem;
  overflow: hidden;
  @include m.media("second") {
    min-width: 5rem;
  }
  @include m.media("mobile") {
    width: 100%;
    min-height: 1.5rem;
  }
  &__filled {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: var(--primary-green-color-lighter);
    border-radius: 0.75rem 0 0 0.75rem;
    height: 100%;
    &.finished {
      border-radius: 0.75rem;
    }
  }
  &__percentage {
    position: relative;
    z-index: 3;
    font-size: 0.875rem;
    @include m.media("second") {
      font-size: 0.75rem;
    }
  }
}