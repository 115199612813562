@use "src/assets/scss/abstracts/variables" as v;

.my-container {
  max-width: 88.5rem;
  margin-inline: auto;
  @media screen and (max-width: 93.75em) {
    max-width: 77.5rem;
  }
  @media screen and (max-width: 78.75em) {
    max-width: 60.625rem;
  }
  @media screen and (max-width: 62em) {
    max-width: 46.875rem;
  }
  @media screen and (max-width: 48em) {
    width: calc(100% - 1.25rem);
  }
}