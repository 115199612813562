@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.detailed-project-navigation {
  & > .block-navigation__list {
    justify-content: space-between;
  }
  &__element {
    padding-block: 0.625rem;
  }
  &__link {
    color: var(--gray-color-3);
    transition: v.$default-transition;
    @include m.media("second") {
      font-size: 0.8em;
    }
    &.active {
      color: var(--black-color);
      $height: 0.25rem;
      @include m.underscore(calc(100% + 1.5rem), calc(100% + 1.875rem + $height), $height);
      @include m.media("second") {
        &::after {
          top: calc(100% + 1.25rem);
        }
      }
    }
    &:hover {
      color: inherit;
    }
  }
}