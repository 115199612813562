@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;
$grid-columns: minmax(10rem, 1fr) minmax(4rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr);
$grid-columns-first: minmax(8rem, 1fr) minmax(2rem, 0.7fr) minmax(6rem, 1fr) minmax(6rem, 1fr) minmax(6rem, 1fr) minmax(6rem, 1fr);
$grid-columns-second: minmax(8rem, 1fr) minmax(2rem, 0.7fr) minmax(6rem, 1fr) minmax(6rem, 1fr) minmax(6rem, 1fr) minmax(6rem, 1fr);
$grid-columns-mobile: 1fr, repeat(5, 1fr);
$grid-columns-last: 1fr 1fr 1fr 1fr;
$grid-areas: item category sum forecast term simple yearly transactions  actions;

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: "primary category sum forecast simple yearly";
  grid-column-gap: 1.25rem;
  @include m.media("first") {
    grid-template-columns: $grid-columns-first;
    grid-column-gap: 1rem;
  }
  @include m.media("second") {
    grid-template-columns: $grid-columns-second;
    grid-column-gap: 0;
  }
  @include m.media("mobile") {
    grid-template-columns: $grid-columns-mobile;
    grid-gap: 0.375rem 0.5rem;
    grid-template-areas:
          "primary primary primary category"
          "sum forecast  simple yearly";
  }
  @include m.media("last") {
    grid-template-columns: $grid-columns-last;
    gap: 0.625rem;
    grid-template-areas:
            "primary primary primary category"
            "sum forecast  simple yearly";
  }
}

.secondary-offer-section {
  padding-bottom: 8.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.secondary-offer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &__top {
    display: flex;
    justify-content: space-between;
  }
  &-heading {
    font-size: 1.5rem;
    @include m.media('mobile') {
      font-size: 1.188rem;
    }
  }
  &-main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &-header {
    @include gridValues;
    @each $area in $grid-areas {
      &__#{$area} {
        display: flex;
        align-items: flex-end;
      }
    }
    background-color: #fbfbfb;
    color: v.$gray-color-3;
    font-size: 0.75rem;
    padding: 25px 47px 7px 25px;
    margin-bottom: 20px;
    @include m.media('last') {
      display: none;
    }
  }
  &-value {
    @include gridValues;
    @media (max-width: 26.25em) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
            "primary category sum"
            "forecast simple yearly";
    }
    @each $area in $grid-areas {
      &__#{$area} {
        display: flex;
        align-items: flex-end;
      }
    }
    padding: 0 47px 22px 24px;
    font-size: 14px;
    align-items: flex-end;
    justify-items: flex-start;
    border-bottom: 4px solid #fbfbfb;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &--code {
      color: v.$gray-color-3;
    }
    &__primary {
      @include m.media("last") {
        grid-column-start: 1;
        grid-column-end: 4;
      }
      @media (max-width: 26.25em) {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }
  }
  &-mobile-header {
    color: v.$gray-color-3;
    font-size: 0.75rem;
    margin-bottom: 20px;
    @include m.media('last','min') {
      display: none;
    }
  }
}

.secondary-offer-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &__container {
    display: grid;
    grid-template-columns: minmax(27.75rem, 1fr) 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
    padding: 1.5rem 1rem 4.25rem 1rem;
    @media screen and (max-width: map-get(v.$container-breakpoints, "second")) {
      grid-template-columns: 1fr;
      & > * {
        grid-column: 1 / -1;
      }
    }
  }
  &__heading {
    grid-column: 1 / -1;
  }
  &__explanation {
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--gray-color-3);
    max-width: 20rem;
    @include m.media("mobile") {
      max-width: initial;
    }
    &-container {
      display: flex;
    }
  }
  &__input {
    display: flex;
    @include m.media('mobile') {
      width: 100%;
    }
    &-calculate {
      background-color: v.$lighter-gray-color;
      display: flex;
      align-items: center ;
      border-radius: 0 0.625rem 0.625rem 0;
      padding-right: 0.5rem;
      position: relative;
      right: 1.5%;
    }
  }
  &__min {
    color: var(--gray-color-3);
    font-size: 0.875rem;
  }
  &__submit {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    padding-inline: 1rem;
    padding-bottom: 1.5rem;
    & > .primary-button {
      font-size: 1.25rem;
    }
  }
  &__check {
    margin-bottom: 1rem;
  }
}

.secondary-offer-calc {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &__type {
    font-size: 0.75rem;
    color: var(--gray-color-3);
    display: flex;
    gap: 0.5rem
  }
  &__value {
    font-size: 0.875rem;
    &--centered {
      @include m.media("second", "min") {
        text-align: center;
      }
    }
  }
  &--first {
    @include m.media("second") {
    grid-column-start: 1;
    grid-column-end: 5;
    }
    @include m.media("mobile") {
    grid-column-start: 1;
    grid-column-end: 3;
    }
  }
}
.secondary-offer__calculation {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  margin-bottom: 3rem;
  grid-gap: 0 2rem;
  padding-inline: 1rem;
  @include m.media("second") {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem 3rem;
  }
  @include m.media("mobile") {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem 3rem;
  }
}