@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.secondary-project-invest {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  position: relative;
  @include m.media("mobile") {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 50;
    overflow: hidden;
  }
  &__header {
    background-color: v.$lighter-gray-color;
    padding: 1.25rem 0.75rem;
    margin: 0.2rem;
    border-radius: 1.25rem 1.25rem 0 0;
    font-size: 1.125rem;
  }
  &__container {
    box-shadow: v.$default-box-shadow;
    border-radius: 1.25rem;
    background-color: v.$white-color;
    position: fixed;
    min-width: 20rem;
    @include m.media("first") {
      max-width: 22.5rem;
    }
    @include m.media("second") {
      max-width: 18rem;
    }
    @include m.media("mobile") {
      max-width: 100vw;
      width: 100%;
      height: 100%;
      position: relative;
      padding-block: 0.75rem;
      transition: v.$default-transition;
      &:not(.active) {
        transform: translateY(6.5rem);
      }
    }
  }
  &__progress {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    @include m.media("mobile") {
      display: none;
    }
  }
  &__deadline {
    font-size: 0.75rem;
    color: var(--gray-color-3);
    text-align: center;
  }
  &__main {
    &--container {
      padding: 1.5rem;
    }
    @include m.media("mobile") {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas:
              "submit"
              "money";
    }
  }
  &__money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    &--price {
      border-bottom: 0.2rem solid v.$lighter-gray-color;
      padding-bottom: 1.5rem;
    }
    @include m.media("mobile", "min") {
      margin-bottom: 0.75rem;
    }
  }
  &__input {
    margin-bottom: 0.75rem;
    @include m.media("second") {
      & .input-money {
        font-size: 1.25rem;
        &__container {
          padding-inline: 0.75rem;
        }
      }
    }
    @include m.media("mobile") {
      padding-bottom: 1.5rem;
    }
    &-btn, &-additional {
      @include m.hideOnBreakpoint("mobile", "min");
    }
    &-additional {
      grid-column: 1 / -1;
      font-size: 0.75rem;
    }
  }
  &__submit {
    @include m.hideOnBreakpoint("mobile");
  }
}