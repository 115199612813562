@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;
@use "../grid" as gr;

.transaction {
  @include gr.gridValues;
  font-size: 0.875rem;
  padding-inline: 1.5rem;
  padding-block: 0.75rem 2.25rem;
  position: relative;
  @include m.media("second") {
    padding-block: 2.25rem 1.5rem;
  }
  @include m.media("mobile") {
    padding-block: 0.5rem 0;
    padding-inline: 1rem;
    cursor: pointer;
    font-size: 0.8rem;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--gray-light-color);
    top: 100%;
  }
  &__column {
    @include m.media("mobile", "min") {
      display: flex;
      align-items: flex-start;
    }
  }
  &--incoming {
    color: var(--primary-green-color);
  }
  &--outgoing {
    color: var(--red-color);
  }
  &__header {
    margin-bottom: 0;
  }
  &__debt {
    grid-area: debt;
    position: relative;
    @include m.media("mobile") {
      flex-direction: column-reverse;
    }
  }
  &__investment {
    grid-area: investment;
  }
  &__date {
    grid-area: date;
    @include m.media(gr.$last-breakpoint) {
      justify-self: end;
    }
  }
  &__hold {
    grid-area: hold;
  }
  &__money {
    grid-area: money;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    @include m.media(gr.$last-breakpoint) {
      justify-self: end;
    }
  }
  &__id {
    color: var(--gray-color-3);
    @include m.media("second") {
      position: absolute;
      top: -1.25rem;
    }
    @include m.media("mobile") {
      position: initial;
    }
  }
  &__type {
    grid-area: type;
    display: flex;
    @include m.media("mobile") {
      align-items: center;
    }
    @include m.media("mobile") {
      margin-right: 0.25rem;
    }
  }
  &.active {
    .transaction__description {
      @include m.media("mobile") {
        max-height: fit-content;
      }
    }
    .transaction__investment, .transaction__date {
      @include m.media(gr.$last-breakpoint) {
        max-height: fit-content;
      }
    }
  }
  &__text {
    @include m.media("mobile") {
      font-size: 0.75rem;
    }
  }
  &__project {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include m.media(41.25em) {
      max-width: 11rem;
    }
  }
  &__investment, &__hold, &__header {
    @include m.media(gr.$last-breakpoint) {
      overflow: hidden;
      max-height: 0;
    }
  }
  &__description {
    grid-area: description;
    @include m.media("mobile") {
      grid-column: 1 / -1;
      overflow: hidden;
      max-height: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-close {
      @include m.box(2rem);
      background-color: var(--gray-color-2);
      border-radius: 50%;
      cursor: pointer;
      @include m.centerWithFlex;
      @include m.hideOnBreakpoint(62em, "min");
    }
  }
}