@use "../grid" as gr;
@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

$grid-areas: primary categoryContainer sum forecast term statusContainer;
$grid-areas-status-container: status date money action;

.ownedInvestment {
  @include gr.gridValues;
  padding: 0.5rem 1.6rem;
  position: relative;
  transition: v.$default-transition;
  background-color: var(--white-color);
  border: 0.3rem solid var(--gray-light-color);

  &:hover {
    color: inherit;
  }

  &__rowHeader {
    font-size: var(--font-size-s);
    color: var(--gray-color-3);
    margin-bottom: 0.375rem;
  }

  &__categoryContainer {
    display: flex;
    flex-direction: row;
    color: inherit;
    @include m.media("mobile") {
      margin-left: 5%;
    }
  }

  &__incasso, &__category {
    font-size: var(--font-size-xs);
    background-color: var(--gray-light-color);
    border-radius: 13px;
    padding: 0.2rem 0.5rem;
    margin-right: 0.3rem;
  }

  &__primary {
    font-size: var(--font-size-s);
    align-self: flex-start;
    @include m.media("mobile") {
      margin-left: 5%;
    }
  }

  &__sum, &__forecast, &__term {
    margin: 0 auto;
    font-size: var(--font-size-sm);
    @include m.media("mobile") {
      font-size: var(--font-size-s);
      margin-left: 10%;
    }
  }

  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }

  @each $area in $grid-areas-status-container {
    &__#{$area} {
      grid-area: #{$area};
    }
  }

  &__linkToProject {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  &__link {
    font-size: var(--font-size-s);
    padding: 0.8em;
  }

  &__status, &__date, &__money, &__actions {
    margin: 0 auto;
    font-size: var(--font-size-sm);
    @include m.media("mobile") {
      font-size: var(--font-size-s);
      margin-left: 10%;
    }
  }

  &__statusContainer {
    display: grid;
    grid-template-columns: repeat(2, 20%) 30% 15%;
    grid-template-areas: "status date money action";
    align-items: center;
    background-color: var(--gray-light-color);
    border-radius: 13px;
    padding: 1rem;
    gap: 1rem;
    @include m.media("mobile") {
      padding: 1rem 0;
      gap: 0.6rem;
      grid-template-columns: 33% repeat(3, 20%)
    }
    @include m.media("last") {
      grid-template-areas:
              "status date"
              "action money";
      grid-template-columns: repeat(2, 48%)
    }
  }

  &__checkbox {
    font-size: var(--font-size-s);
  }
}