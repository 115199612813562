@use "src/assets/scss/abstracts/mixins" as m;

.my-investments {
  &__block-body {
    padding-block: 0 0;
  }
  &__paginator {
    @include m.centerWithFlex;
    padding-bottom: 1rem;
  }
  &__download-container {
    position: relative;
  }
  &__download {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    @include m.media("mobile") {
      top: 0.25rem;
      left: 90%;
    }
    @include m.media("last") {
      left: 85%;
    }
  }
}