@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.identification-date-select {
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  border: 2px solid v.$gray-color-2;
  padding: 0.5rem;
  &__label {
    padding-left: 0.25rem;
    @include m.media(41.25em) {
      font-size: 0.7rem;
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
    border-radius: 0.625rem;
    border: 2px solid v.$gray-color-2;
    padding: 0.4rem 1.5rem;
    gap: 0.5rem;
    @include m.media(41.25em) {
      padding: 0.4rem;
      font-size: 0.75rem;
    }
  }
  & .date-select {
    & .react-date-picker__calendar {
      inset: 100% auto auto auto !important;
      width: 250px;
      z-index: 5;
    }
  }
}