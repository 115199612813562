@use "src/assets/scss/abstracts/variables" as v;
@use "src/components/pages/detailed-project/styles/mixins" as dm;

.project-strategy {
  @include dm.mountAnimation;
  &__heading {
    font-size: 1.5rem;
    margin-bottom: 0.375rem;
  }
  &__list {
    list-style-type: circle;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__bold {
    font-weight: 700;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  &__block {
    padding-block: 1.5rem;
    transition: v.$default-transition;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    &:first-child {
      padding-top: 0;
    }
    &:not(:last-child) {
      padding-bottom: 2rem;
      &::after {
        content: "";
        position: absolute;
        width: calc(100% + 2rem);
        height: 0.5rem;
        background-color: var(--gray-light-color);
        left: -1rem;
        bottom: 0;
      }
    }
  }
}