@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.agent-assignments__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @include m.media("last") {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.125rem 0;
  }
  & > * {
    &:last-child {
      text-align: right;
    }
    @include m.media("mobile") {
      &:last-child {
        text-align: center;
      }
    }
    @include m.media("last", "min") {
      &:nth-child(3) {
        text-align: center;
      }
    }
    @include m.media("last") {
      &:nth-child(2n) {
        text-align: right;
      }
    }
  }
}