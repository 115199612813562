.enter-creds {
  &__heading {
    margin-bottom: 1rem;
  }
  &__text {
    margin-bottom: 3rem;
    font-size: 0.875rem;
  }
  &__form {
    display: flex;
    flex-direction: column;
  }
}