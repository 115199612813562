@use "src/assets/scss/abstracts/mixins" as m;
@use "../grid" as gr;

$grid-areas: item category sum actual-payment forecast term simple yearly transactions stages actions;

.active-projects-header {
  @include gr.gridValues;
  @each $area in $grid-areas {
    &__#{$area} {
      display: flex;
      align-items: flex-end;
    }
  }
}

