@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.survey__input-group {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @include m.media("last") {
    padding: 0.5rem;
  }

  &__question {
    margin-bottom: 1rem;
    @include m.media("last") {
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }
  }

  &__input {
    border-radius: 0.625rem;
    border: 2px solid v.$gray-color-2;
    padding: 1.125rem;
    min-height: 3.5rem;
    background-color: inherit;

    &:focus {
      outline: none;
    }
  }
}