@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.bank-account-select {
  width: 100%;
  min-height: 3rem;
  border-radius: 0.625rem;
  background-color: var(--gray-light-color);
  padding-block: 0.5rem;
  padding-inline: 1.25rem 0.5rem;
  position: relative;
  @include m.centerLineWithFlex;
  justify-content: space-between;
  cursor: pointer;
  &__number {
    font-size: 1.5rem;
    color: var(--gray-color-3);
  }
  &__button {
    @include m.box(2rem);
    border-radius: 50%;
    content: '';
    transition: all 0.3s;
    background-color: var(--primary-green-color-lighter);
    background-image: url("../../../../assets/images/svg/green-arrow-up.svg");
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
    &.active {
      transform: rotate(0);
    }
  }
  &__dropdown {
    position: absolute;
    z-index: 30;
    top: 100%;
    right: 0;
    border-radius: 0.625rem;
    background-color: var(--white-color);
    box-shadow: 0 3px 3.125rem rgba(v.$black-color, 0.1);
    overflow: hidden;
    max-height: 0;
    transition: v.$default-transition;
    &.active {
      overflow-y: scroll;
      max-height: 10rem;
    }
  }
  &__element {
    padding: 0.625rem 1rem;
    &:hover {
      color: var(--primary-green-color);
    }
  }
}