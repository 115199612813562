@use "src/assets/scss/abstracts/mixins" as m;

.my-projects-nav {
  @include m.hideOnBreakpoint("mobile");
  &__list {
    display: flex;
    align-items: stretch;
    gap: 4rem;
  }
  &__element {
    padding-block: 0.625rem;
  }
  &__link {
    display: block;
    color: var(--gray-color-3);
    &.active {
      @include m.underscore(calc(100% + 1.5rem), calc(100% + 1.375rem), 0.25rem);
      font-weight: 700;
    }
    &:hover, &.active {
      color: var(--black-color);
    }
  }
}