@use "../../../../../assets/scss/abstracts/variables" as v;

.identification-block {
  border-radius: 1.25rem;
  background-color: var(--white-color);
  padding: 1.75rem 2rem 2.25rem 2rem;
  position: relative;
  box-shadow: 0 3px 2rem rgba(v.$black-color, 0.05);
  &__info {
    position: absolute;
    top: 1.75rem;
    right: 2rem;
  }
}