@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.my-money {
  &__icon {
    @include m.box(2.5rem);
    background-color: var(--primary-green-color-lighter);
    border-radius: 50%;
    @include m.centerWithFlex;
  }
  &__block-header {
    min-height: 5.625rem;
    padding-block: 1rem;
    @include m.media("second") {
      min-height: 5rem;
    }
  }
  &__portfolio {
    //@include m.media("last") {
    //  margin-top: 2rem;
    //}
    @media(max-width:23.438em) {
      margin-top:2em;
    }
    grid-area: portfolio;
    & > * {
      height: 100%;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-inner {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  &__heading {
    font-size: 1.25rem;
    &--main {
      font-size: 1.5rem;
      @include m.media("second") {
        font-size: 1.25rem;
      }
      @include m.media("last") {
        font-size: 1rem;
      }
    }
  }
}
