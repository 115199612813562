@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

$grid-areas: important, status, content, date, file;

.notification {
  padding-block: 1rem;
  display: grid;
  grid-template-areas:
          "blank important important"
          "status content date"
          "blank2 file blank3";
  grid-template-columns: 1rem 1fr minmax(10ch, max-content);
  grid-gap: 0 1.125rem;
  @include m.media("first") {
    grid-template-areas:
          "blank important important"
          "status content date"
          "blank2 file file";
  }
  @include m.media("last") {
    grid-template-columns: 1rem 1fr;
    grid-gap: 0.25rem 1.125rem;
    grid-template-areas:
          "blank important"
          "status content"
          "status blank2"
          "status blank2"
          "status date"
          "status file";
  }
  &:not(:last-child) {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: -1rem;
      width: calc(100% + 2rem);
      height: 2px;
      background-color: var(--gray-light-color);
    }
  }
  @each $area in $grid-areas {
    &__#{$area} {
      grid-area: #{$area};
    }
  }
  &__important {
    font-size: 0.875rem;
    text-transform: uppercase;
    color: var(--primary-orange-color-2);
  }
  &__date {
    font-size: 0.875rem;
    color: var(--gray-color-3);
    line-height: 1.125rem;
    @include m.media("last") {
      display: flex;
      gap: 0.75rem;
    }
  }
  &__file {
    margin-top: 1.5rem;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 2.75rem 1.25rem 1.5rem;
    border-radius: 0.675rem;
    font-size: 0.875rem;
    @include m.media("last") {
      margin-top: 1rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.375rem;
    }
    &-title {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: var(--gray-color-3);
    }
    &-name {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        height: 75%;
        width: 1px;
        background-color: var(--gray-color-3);
        @include m.centerColumnWithPositioning;
        right: -0.5rem;
      }
    }
    &-url {
      color: var(--primary-green-color);
    }
  }
}