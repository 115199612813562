@use "../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../assets/scss/abstracts/variables" as v;

.my-modal {
  position: fixed;
  @include m.centerWithPositioning;
  background-color: var(--white-color);
  padding: 0.75rem;
  border-radius: 1.25rem;
  width: calc(min(32rem, calc(100% - 1rem)));
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  z-index: 1055;
  overflow-y: scroll;
  box-shadow: v.$default-box-shadow;
  &__content {
    position: relative;
  }
  &--enter {
    opacity: 0;
    transform: translateX(-50%) translateY(-10rem);
  }
  &--enter-active {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
    transition: all 0.5s;
  }
  &--exit {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
  &--exit-active {
    opacity: 0;
    transform: translateX(-50%) translateY(-10rem);
    transition: all 0.5s;
  }
}