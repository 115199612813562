@use "src/assets/scss/abstracts/mixins" as m;

.filter-search {
  display: flex;
  align-items: center;
  position: relative;
  &__input {
    width: 0;
    padding: 0;
    min-height: 2.5rem;
    border-radius: 1.25rem;
    border: 0;
    background-color: var(--white-color);
    transition: all 0.5s;
    transform: translateX(2.5rem);
    font-size: 90%;
    &:focus {
      outline: none;
    }
    @include m.media("mobile", "min") {
      position: absolute;
      &--exit-done {
        position: absolute;
      }
      &--enter, &--enter-active, &--enter-done, &--exit, &--exit-active {
        position: relative;
      }
      &.active {
        padding-inline: 1rem 2.5rem;
        margin-left: -1.5rem;
        width: 25rem;
        max-width: 100%;
      }
    }
    @include m.media("mobile") {
      position: absolute;
      left: 1.5rem;
      opacity: 0;
      width: calc(100% - 10rem);
      z-index: -1;
      padding-left: 0;
      &.active {
        opacity: 1;
        z-index: 5;
        padding-left: 1rem;
      }
    }
    @include m.media("last") {
      &.active {
        left: -18.5rem;
        width: calc(100% + 11rem);
      }
    }
  }
}