@use "../../../../../../components/pages/detailed-project/styles/mixins" as dm;
@use "../../../../../../assets/scss/abstracts/mixins" as m;
@use "../../../../../../assets/scss/abstracts/variables" as v;


.documents {
  &__container {
    width: 100%;
    @include m.media("last") {
      overflow: scroll;
      height: 3rem;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__list {
    @include m.centerLineWithFlex;
    justify-content: stretch;
    @include m.media("last") {
      width: 180%;
    }
  }

  &__element {
    width: 100%;
    text-align: center;
  }


  &__link {
    display: block;
    padding: 1.25rem;
    color: var(--gray-light-color-2);
    font-size: var(--font-size-m);
    @include m.underscore();
    transition: color 0.3s;

    &:hover {
      color: var(--primary-green-color);
    }

    &.active {
      color: var(--black-color);

      & > .lender-navigation__link-image {
        border-radius: 50%;
        background-color: var(--primary-green-color-lighter);
      }
    }

    @include m.media("last") {
      font-size: var(--font-size-s);
      padding: 0.8rem;
    }
  }

}