@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

.autoinvest {
  display: grid;
  grid-template-columns: 1fr 30rem;
  grid-gap: 0 1rem;
  @include m.media("first") {
    grid-template-columns: 1fr 32rem;
  }
  @include m.media("second") {
    grid-template-columns: 1fr 23.75rem;
  }
  @include m.media("mobile") {
    grid-template-columns: 1fr;
    grid-gap: 2rem 0;
  }
  @include m.media("last") {
    grid-row-gap: 1rem;
  }
  &-placeholder {
    min-height: 6rem;
    text-align: center;
  }
  &__loading {
    text-align: center;
  }
  &__element {
    &:not(:last-child) {
      & > * {
        padding-bottom: 1.5rem;
      }
    }
    @include m.media("second", "min") {
      & > * {
        //height: 100%;
      }
    }
    @include m.media("mobile") {
      &:first-child {
        grid-row: 2;
      }
    }
  }
  &__toggle {
    display: flex;
    align-items: center;
    gap: 1rem;
    &-text {
      color: var(--gray-light-color-2);
      font-size: 1.5rem;
      cursor: pointer;
      @include m.media("mobile") {
        font-size: var(--font-size-m);
      }
      &.active {
        color: var(--primary-green-color);
      }
    }
  }
  &__text {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  &__heading {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    @include m.media("second") {
      font-size: 0.75rem;
    }
    @include m.media("last") {
      font-size: 0.875rem;
      display: flex;
      gap: 3px;
      margin-bottom: 0.5rem;
    }
    &-info {
      margin-block: auto;
    }
  }
  &__additional-submit {
    @include m.hideOnBreakpoint("last", "min");
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__explanation {
    @include m.hideOnBreakpoint("mobile", "min");
    font-size: 0.625rem;
    color: var(--gray-color-3);
    margin-top: 0.875rem;
  }
  &__debt-class {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__block-heading {
    @include m.media("last", "min") {
      font-size: 1.5rem;
    }
  }
  &__container {
    &:not(:last-child) {
      border-bottom: 2px solid var(--gray-light-color);
      padding-bottom: 1.25rem;
    }
    & > * {
      padding-inline: 1.25rem;
    }
    &:not(:first-child) {
      padding-top: 1.25rem;
    }
    @include m.media("last") {
      &:not(:last-child) {
        border-bottom: 2px solid var(--gray-light-color);
        padding-bottom: 1rem;
      }
      &:not(:first-child) {
        padding-top: 1rem;
      }
    }
  }
  &__left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
  }
  &__parameters-grid {
    display: grid;
    grid-gap: 1.5rem;
    @include m.media("last", "min") {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__settings-body {
    @include m.media("mobile") {
      padding-bottom: 3rem;
    }
    @include m.media("last") {
      padding-bottom: 0;
    }
  }
  &__submit {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
    & .primary-button {
      font-size: 1.25rem;
      padding-block: 1em;
      @include m.media("second") {
        font-size: 1.1rem;
      }
    }
    &__info{
      position: absolute;
      right: 2rem;
      top: 0.5rem;
      &__block {
        left: -5rem;
        @include m.media("second") {
          left: -3rem;
        }
        &::after {
          left: 12.5rem;
          @include m.media("second") {
            left: 8.5rem;
          }
        }
      }
    }
  }
  &__slider-container {
    margin-top: 2rem;
    @include m.media("last", "min") {
      max-width: 80%;
    }
  }
  &__rules {
    margin-top: 1.125rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    &-size {
      white-space: nowrap;
    }
  }
}

.autoinvest-incasso {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  &__title {
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 1rem;

  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.same-borrower__container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}


.block__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}