@use "src/assets/scss/abstracts/mixins" as m;

.projects-header-options {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @include m.media("mobile") {
    position: absolute;
    inset: 0;
    justify-content: flex-end;
  }
  @include m.media("last") {
    justify-content: flex-start;
    flex-direction: column;
    left: 90%;
  }
}