@use "src/assets/scss/abstracts/variables" as v;
@use "src/assets/scss/abstracts/mixins" as m;

$grid-columns: minmax(12rem, 1fr) 4rem minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) 4rem 5rem 5rem 4rem 4rem minmax(5rem, 1fr);
$grid-columns-first: minmax(9rem, 1fr) 4rem minmax(7rem, 1fr) minmax(7rem, 1fr) minmax(7rem, max-content) 4rem 5rem 5rem 4rem 4rem minmax(4rem, 1fr);
$grid-columns-second:
        minmax(7rem, 1fr)
        4rem
        minmax(7rem, 1fr)
        minmax(7rem, 1fr)
        minmax(7rem, 1fr)
        2.75rem
        minmax(4.25rem, 1fr)
        minmax(4.25rem, 1fr)
        5rem
        minmax(4rem, 1fr)
        minmax(4rem, 1fr);
$grid-columns-mobile: repeat(7, 1fr);
$grid-columns-last: 1fr 1fr 2fr 5rem;

@mixin gridValues() {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-template-areas: "primary category sum actual-payment forecast term simple yearly transactions stages actions";
  grid-column-gap: 1.25rem;
  @include m.media("first") {
    grid-template-columns: $grid-columns-first;
    grid-column-gap: 1rem;
  }
  @include m.media("second") {
    grid-template-columns: $grid-columns-second;
    grid-column-gap: 0;
  }
  @include m.media("mobile") {
    grid-template-columns: $grid-columns-mobile;
    grid-gap: 0.375rem 0.5rem;
    grid-template-areas:
          "primary primary primary primary primary primary category"
          "sum actual-payment forecast term simple yearly stages"
          "transactions actions actions actions actions actions actions";
  }
  @include m.media("last") {
    grid-template-columns: $grid-columns-last;
    grid-template-areas:
          "primary primary primary category"
          "sum sum forecast term"
          "simple yearly actual-payment actual-payment"
          "stages transactions transactions transactions"
          "actions actions actions actions";
  }
}