@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.agent-assignment {
  padding-block: 1.5rem;
  @include m.media("last") {
    padding-block: 1rem;
  }
  &__text {
    @include m.media("last") {
      font-size: 0.875rem;
    }
  }
  &__date {
    @include m.media("last") {
      color: var(--gray-light-color-2);
    }
  }
  &__status {
    &-placeholder {
      @include m.hideOnBreakpoint("last", "min");
    }
  }
  &:not(:last-child) {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: calc(100% + 2rem);
      height: 2px;
      left: -1rem;
      top: 100%;
      background-color: var(--gray-light-color);
    }
  }
  &__link {
    color: var(--primary-green-color);
    &:hover {
      color: var(--black-color);
    }
  }
}