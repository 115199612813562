@use "src/assets/scss/abstracts/mixins" as m;

.hamburger {
  @include m.box(2.5rem);
  cursor: pointer;
  border-radius: 50%;
  background-color: #fbfbfb;
  padding: 0.625rem;
  &__inner {
    @include m.box(100%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 0.25rem;
  }
  &__row {
    display: block;
    width: 1.2rem;
    height: 2px;
    &:not(:first-child):not(:last-child) {
      width: 0.875rem;
    }
  }
  &--green {
    & span {
      background-color: var(--primary-green-color);
    }
  }
  &--black {
    & span {
      background-color: var(--black-color);
    }
  }
}